import Erc20 from './erc20'
import { AbiItem, AbiType } from 'web3-utils'
import config from '../config'
import RcaShield from './rcaShield'
import RcaController from './rcaController'
import CapacityOracle from './capacityOracle'
import PriceOracle from './priceOracle'
import Guardian from './guardian'
import Governance from './governance'
import GvToken from './gvToken/gvToken'
import TokenSwap from './gvToken/tokenSwap'
import BribePot from './gvToken/bribePot'
import EaseToken from './easeToken'

export interface ContractInterface {
  address: string
  abi: AbiItem[]
}

export type ContractDataType = Record<
  keyof ContractListInterface,
  ContractInterface
>

export interface ContractListInterface {
  governance: ContractInterface
  guardian: ContractInterface
  priceOracle: ContractInterface
  capacityOracle: ContractInterface
  rcaShield: ContractInterface
  rcaController: ContractInterface
  underlyingToken: ContractInterface
  gvToken: ContractInterface
  tokenSwap: ContractInterface
  bribePot: ContractInterface
  erc20: ContractInterface
  easeToken: ContractInterface
  armorToken: ContractInterface
  vArmorToken: ContractInterface
}

const Contracts: ContractDataType = {
  governance: Governance(config.GOVERNANCE_ADDRESS),
  guardian: Guardian(config.USER_GUARDIAN_ADDRESS),
  priceOracle: PriceOracle(config.PRICE_ORACLE_ADDRESS),
  capacityOracle: CapacityOracle(config.CAPACITY_ORACLE_ADDRESS),
  rcaShield: RcaShield(config.SHEILD_ADDRESS),
  rcaController: RcaController(config.CONTROLLER_ADDRESS),
  underlyingToken: Erc20(config.UNDERLYING_TOKEN_ADDRESS),
  gvToken: GvToken(config.GV_TOKEN_ADDRESS),
  tokenSwap: TokenSwap(config.TOKEN_SWAP_ADDRESS),
  bribePot: BribePot(config.BRIBE_POT_ADDRESS),
  easeToken: EaseToken(config.EASE_TOKEN_ADDRESS),
  erc20: Erc20(config.EASE_TOKEN_ADDRESS),
  armorToken: Erc20(config.ARMOR_TOKEN_ADDRESS),
  vArmorToken: Erc20(config.VARMOR_ADDRESS)
}

export default Contracts
