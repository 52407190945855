import { NavIcon } from './NavIcon'
import DiscordIcon from './icons/DiscordIcon'
import TwitterIcon from './icons/TwitterIcon'
import RedditIcon from './icons/RedditIcon'
import GithubIcon from './icons/GithubIcon'
import LinkedInIcon from './icons/LinkedInIcon'
import React, { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { MenuIcon } from '@heroicons/react/solid'
import { classNames, shrinkSplit } from '../classes/helpers'
import { WalletConnect } from './WalletConnect'
import { NavLink } from './NavLink'
import { useAppSelector } from '../hooks/redux'
import {
  selectImpersonationAddress,
  selectIsDevelopment
} from '../store/globalSlice'
import { useImpersonatableWeb3React } from '../hooks/useImpersonatableWeb3React'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'

export const Header: React.FC<{
  children?: React.PropsWithChildren<any>
}> = ({}) => {
  const [open, setOpen] = useState(false)
  const { account } = useImpersonatableWeb3React()
  const impersonationAddress = useAppSelector(selectImpersonationAddress)
  const isDevelopment = useAppSelector(selectIsDevelopment)
  const location = useLocation()

  const navigation = [
    {
      name: 'Dashboard',
      href: '/',
      current: location.pathname == '/'
    },
    {
      name: 'gvDashboard',
      href: '/gv-dashboard',
      current: location.pathname == '/gv-dashboard'
    },
    {
      name: 'Bribing',
      href: '/gv-bribing',
      current: location.pathname == '/gv-bribing'
    },
    {
      name: 'Token Swap',
      href: '/token-swap',
      current: location.pathname == '/token-swap'
    },
    //{
    //  name: 'Tx History',
    //  href: '/tx-history',
    //  current: location.pathname == '/tx-history'
    //},
    {
      name: 'About',
      href: 'https://ease.org/about-ease-defi/',
      current: false
    },
    { name: 'Learn', href: 'https://ease.org/learn/', current: false },
    {
      name: 'Get Involved',
      href: 'https://ease.org/get-involved/',
      current: false
    }
  ]

  return (
    <div
      className={
        'bg-white w-full flex justify-between items-center md:py-4 md:px-4 md:pl-8'
      }
    >
      <div className={'hidden lg:flex  items-center'}>
        <NavLink url={'/'} active={location.pathname == '/'}>
          <img src={'/logo.png'} alt={'ease.org'} className={'h-12'} />
        </NavLink>
        <div className={'pl-4 flex gap-x-4'}>
          <NavLink url={'/'} active={location.pathname == '/'}>
            App
          </NavLink>
          <NavLink
            url={'/gv-dashboard'}
            active={location.pathname == '/gv-dashboard'}
          >
            gvEase
          </NavLink>
          <NavLink
            url={'/gv-bribing'}
            active={location.pathname == '/gv-bribing'}
          >
            Bribes
          </NavLink>
          <NavLink
            url={'/token-swap'}
            active={location.pathname == '/token-swap'}
          >
            Token Swap
          </NavLink>
        </div>
      </div>
      <div className={'hidden lg:flex items-center gap-x-10'}>
        <div className={'flex gap-x-4 text-base font-poppins items-center'}>
          <a href={'https://ease.org/about-ease-defi/'} target={'_blank'}  rel={'nofollow noreferrer'} >About</a>
          <a href={'https://ease.org/learn/'} target={'_blank'}  rel={'nofollow noreferrer'}>Learn</a>
          <a href={'https://ease.org/get-involved/'} target={'_blank'}  rel={'nofollow noreferrer'}>Get Involved</a>
        </div>
        <div className={'mt-1 hidden xl:flex gap-x-1'}>
          <a
            href={'https://discord.gg/9JVTdFXdgF'}
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            <DiscordIcon classes={'fill-blue-900'} />
          </a>
          <a
            href={'https://twitter.com/easeDeFi'}
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            <TwitterIcon classes={'fill-blue-900'} />
          </a>
          <a
            href={'https://www.reddit.com/r/easeDeFi'}
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            <RedditIcon classes={'fill-blue-900'} />
          </a>
          <a
            href={'https://github.com/easeDeFi'}
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            <GithubIcon classes={'fill-blue-900'} />
          </a>
          <a
            href={'https://www.linkedin.com/company/easeDeFi'}
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            <LinkedInIcon classes={'fill-blue-900'} />
          </a>
        </div>
        <div className={'connect-wallet'}>
          {account && (
            <div
              className={clsx(
                'relative mt-1 text-sm text-white px-3 py-1 rounded-md font-poppins cursor-pointer',
                impersonationAddress != '' ? 'bg-green-500' : 'bg-pink-500'
              )}
              onClick={() => setOpen(true)}
            >
              <span>{shrinkSplit(account.toLowerCase(), 8)}</span>
            </div>
          )}
          {!account && (
            <div
              className="inline-flex items-center px-3 py-1 text-sm font-medium font-bold text-white uppercase bg-pink-500 border border-transparent rounded-md shadow-sm cursor-pointer hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              onClick={() => setOpen(true)}
            >
              Connect
            </div>
          )}
        </div>
      </div>

      <Disclosure as="nav" className="w-full bg-white lg:hidden">
        {({ open }) => (
          <>
            <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block w-6 h-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex items-center justify-center flex-1 lg:items-stretch lg:justify-start">
                  <div className="flex items-center flex-shrink-0">
                    <img
                      src={'/logo.png'}
                      alt={'ease.org'}
                      className={'h-12'}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation
                  .filter(
                    (x) =>
                      isDevelopment ||
                      (!isDevelopment && x.name != 'Test Cases')
                  )
                  .map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-pink-500 text-white'
                          : 'text-gray-700 hover:bg-gray-200 hover:text-black',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                {!account && (
                  <div className={'pt-1'}>
                    <div
                      className="inline-flex items-center justify-center w-full px-3 py-2 text-sm font-medium font-bold text-white uppercase bg-blue-900 border border-transparent rounded-md shadow-sm cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                      onClick={() => setOpen(true)}
                    >
                      Connect to Wallet
                    </div>
                  </div>
                )}
                {account && (
                  <div className={'pt-3 border-t border-gray-200'}>
                    <div
                      className={
                        'text-sm text-blue-900 px-3 py-1 rounded-md font-poppins cursor-pointer text-center'
                      }
                      onClick={() => setOpen(true)}
                    >
                      <span>{account.toLowerCase()}</span>
                    </div>
                  </div>
                )}
                <div className={'pt-3'}>
                  <div
                    className={
                      'flex flex-wrap justify-center gap-x-4 w-2/3 mx-auto gap-y-2'
                    }
                  >
                    <a
                      href={'https://discord.gg/9JVTdFXdgF'}
                      target={'_blank'}
                      rel={'nofollow noreferrer'}
                      className={'flex items-center gap-x-1'}
                    >
                      <DiscordIcon classes={'fill-blue-900'} /> Discord
                    </a>
                    <a
                      href={'https://twitter.com/easeDeFi'}
                      target={'_blank'}
                      rel={'nofollow noreferrer'}
                      className={'flex items-center gap-x-1'}
                    >
                      <TwitterIcon classes={'fill-blue-900'} /> Twitter
                    </a>
                    <a
                      href={'https://www.reddit.com/r/easeDeFi'}
                      target={'_blank'}
                      rel={'nofollow noreferrer'}
                      className={'flex items-center gap-x-1'}
                    >
                      <RedditIcon classes={'fill-blue-900'} /> Reddit
                    </a>
                    <a
                      href={'https://github.com/easeDeFi'}
                      target={'_blank'}
                      rel={'nofollow noreferrer'}
                      className={'flex items-center gap-x-1'}
                    >
                      <GithubIcon classes={'fill-blue-900'} /> GitHub
                    </a>
                    <a
                      href={'https://www.linkedin.com/company/easeDeFi'}
                      target={'_blank'}
                      rel={'nofollow noreferrer'}
                      className={'flex items-center gap-x-1'}
                    >
                      <LinkedInIcon classes={'fill-blue-900'} /> Linked In
                    </a>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <WalletConnect onClose={() => setOpen(false)} open={open} />
    </div>
  )
}
