import { Button } from '../Button'
import React, { ReactNode, useMemo, useRef, useState } from 'react'
import {
  formatDecimals,
  makeWeb3Contract,
  weiToEth
} from '../../classes/helpers'
import {
  ClockIcon,
  LightningBoltIcon,
  LockOpenIcon
} from '@heroicons/react/solid'
import { GetUserStatsResponse } from '../../hooks/gvDashboard/useGetUserStats'
import Contracts from '../../contracts'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import useContractMethodSend from '../../hooks/useContractMethodSend'
import { useImmer } from 'use-immer'
import { AmountModal, AmountModalData } from '../AmountModal'
import { useImpersonatableWeb3React } from '../../hooks/useImpersonatableWeb3React'
import { LoadingModal } from '../LoadingModal'
import { ConfirmationModal } from '../ConfirmationModal'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { BigNumber } from 'ethers'
import { Tooltip } from 'react-tippy'

type ButtonVariant = 'orange' | 'gray' | 'blackop' | 'pink' | 'white' | 'blue'

interface StatData {
  name: string
  stat: string
  button: string
  buttonVariant: ButtonVariant
  buttonClick: Function
  buttonDisabled: boolean
  icon: ReactNode
  buttonSecondary?: string
  buttonSecondaryClick?: Function
  buttonSecondaryVariant?: ButtonVariant
  tooltipTitle?: string
}

export const StatsCard: React.FC<{
  children?: React.PropsWithChildren<any>
  stats: GetUserStatsResponse
  onUpdate: Function
}> = ({ stats, onUpdate }) => {
  const { account, chainId, library } = useImpersonatableWeb3React()
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false)
  const [loadingModalTitle, setLoadingModalTitle] = useState<string>('')
  const [loadingModalBody, setLoadingModalBody] = useState<ReactNode>('')
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const amountModalRef = useRef('0')
  const [amountModalObj, setAmountModalObj] = useImmer<AmountModalData>(
    {} as AmountModalData
  )

  const onError = (err: Error) => {
    setLoadingModalOpen(false)
  }

  const onDeposit = () => {
    amountModalRef.current = formatUnits(stats.easeBalance.value, 18)
    setAmountModalObj((v) => {
      v.maxAmount = stats.easeBalance.value
      v.title = 'Deposit Unstaked $Ease'
      v.isOpen = true
      v.buttonText = 'Confirm Deposit'
      v.onConfirm = () => onDepositConfirm()
      return v
    })
  }

  const onDepositSuccess = () => {
    setLoadingModalOpen(false)
    Promise.all([
      stats.easeEarned.get([account]),
      stats.easeBalance.get([account]),
      stats.totalEaseDeposits.get([account]),
      stats.gvTokenBalanceOf.get([account]),
      onUpdate()
    ]).catch((err) => console.error(err))
  }

  const onDepositApprovalRequired = () => {
    setAmountModalObj((v) => {
      v.isOpen = false
      return v
    })
    setConfirmationModalOpen(true)
  }

  const onDepositConfirm = async () => {
    setAmountModalObj((v) => {
      v.isOpen = false
      return v
    })

    let amount = parseUnits(amountModalRef.current, 18)
    if (amount.gt(BigNumber.from(stats.easeBalance.value))) {
      amount = stats.easeBalance.value
    }

    const _contract = makeWeb3Contract(
      library.currentProvider,
      Contracts.easeToken,
      Contracts.easeToken.address
    )
    let result: BigNumber = await _contract.methods['allowance'](
      account,
      Contracts.gvToken.address
    ).call({
      from: account
    })
    if (BigNumber.from(result).gte(amount)) {
      onApproveSuccess()
      return
    }

    setLoadingModalOpen(true)
    setLoadingModalTitle('Approve $Ease for Deposit')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm the transaction to approve{' '}
          <span className={'text-pink-500'}>
            {formatDecimals(Number(formatUnits(amount, 18)), 4, 2)}
          </span>{' '}
          $Ease.
        </div>
      </>
    )

    await depositApprove([Contracts.gvToken.address, amount]).catch((err) =>
      console.error(err)
    )
  }

  const onWithdraw = () => {
    amountModalRef.current = formatUnits(stats.totalEaseDeposits.value, 18)
    setAmountModalObj((v) => {
      v.maxAmount = stats.totalEaseDeposits.value
      v.title = 'Withdraw $Ease'
      v.isOpen = true
      v.buttonText = 'Confirm Withdrawal'
      v.onConfirm = () => onWithdrawConfirm()
      return v
    })
  }

  const onWithdrawConfirm = async () => {
    setAmountModalObj((v) => {
      v.isOpen = false
      return v
    })
    setLoadingModalOpen(true)
    setLoadingModalTitle('Withdraw $Ease')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm the transaction to start a withdrawal request for{' '}
          <span className={'text-pink-500'}>
            {formatDecimals(Number(amountModalRef.current), 4, 2)}
          </span>{' '}
          $Ease.
          <div className={'text-gray-700 mt-4'}>
            Withdraw will be finalized in 7 days.
          </div>
        </div>
      </>
    )

    await withdrawRequest([parseUnits(amountModalRef.current, 18)]).catch(
      (err) => console.error(err)
    )
  }
  const onWithdrawRequestSuccess = () => {
    setLoadingModalOpen(false)
    Promise.all([
      stats.easeEarned.get([account]),
      stats.easeBalance.get([account]),
      stats.totalEaseDeposits.get([account]),
      stats.withdrawRequests.get([account]),
      stats.gvTokenBalanceOf.get([account]),
      onUpdate()
    ]).catch((err) => console.error(err))
  }

  const withdrawRequest = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'withdrawRequest',
    onSuccess: onWithdrawRequestSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const deposit = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'deposit',
    onSuccess: onDepositSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const onApproveSuccess = () => {
    setLoadingModalOpen(true)
    setLoadingModalTitle('Deposit $Ease')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm the transaction to deposit{' '}
          <span className={'text-pink-500'}>
            {formatDecimals(Number(amountModalRef.current), 4, 2)}
          </span>{' '}
          $Ease.
        </div>
      </>
    )

    // cap amount to ease balance
    let amount = parseUnits(amountModalRef.current, 18)
    if (amount.gt(BigNumber.from(stats.easeBalance.value))) {
      amount = stats.easeBalance.value
    }

    deposit([
      amount,
      [
        '0x0000000000000000000000000000000000000000',
        0,
        '0x0000000000000000000000000000000000000000000000000000000000000000',
        '0x0000000000000000000000000000000000000000000000000000000000000000'
      ]
    ]).catch((err) => console.error(err))
  }

  const depositApprove = useContractMethodSend({
    contract: Contracts.easeToken,
    methodName: 'approve',
    onSuccess: onApproveSuccess,
    onError: onError,
    address: Contracts.easeToken.address
  })

  const onClaimRewardSuccess = () => {
    setLoadingModalOpen(false)
    Promise.all([
      stats.deposits.get([account]),
      stats.easeEarned.get([account]),
      stats.easeBalance.get([account]),
      onUpdate()
    ])
      .catch((err) => console.error(err))
      .then((res) => {
        //console.log('loaded')
      })
  }

  const onClaimAndDepositRewards = () => {
    setLoadingModalOpen(true)
    setLoadingModalTitle('Claim Rewards and Deposit')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm the transaction to claim rewards and deposit.
        </div>
      </>
    )
    claimRewardsAndDeposit().catch((err) => console.error(err))
  }

  const claimRewardsAndDeposit = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'claimAndDepositReward',
    onSuccess: onClaimRewardSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const onBuyEase = () => {
    window.open(
      'https://app.uniswap.org/#/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=0xea5edef1287afdf9eb8a46f9773abfc10820c61c',
      '_blank'
    )
  }

  const onClaimRewards = () => {
    setLoadingModalOpen(true)
    setLoadingModalTitle('Claim Rewards')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm the transaction to claim rewards.
        </div>
      </>
    )
    claimReward().catch((err) => console.error(err))
  }

  const claimReward = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'claimReward',
    onSuccess: onClaimRewardSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const statsData: StatData[] = useMemo(
    () => [
      {
        name: '$Ease in Wallet',
        stat: stats.easeBalance
          ? formatDecimals(weiToEth(stats.easeBalance.value), 3, 0)
          : '0',
        button: 'Deposit',
        buttonVariant: 'pink',
        buttonClick: () => onDeposit(),
        buttonDisabled:
          !stats.easeBalance || Number(stats.easeBalance.value) == 0,
        icon: <LockOpenIcon className={'w-8 h-8 text-blackop-30'} />,
        buttonSecondary: 'BUY EASE',
        buttonSecondaryClick: () => onBuyEase(),
        buttonSecondaryVariant: 'pink'
        //tooltipTitle:
        //  'You can deposit your $Ease tokens to get $gvEase tokens in return. You can then use these to lease or stake.'
      },
      {
        name: '$Ease Deposited',
        stat: stats.totalEaseDeposits
          ? formatDecimals(weiToEth(stats.totalEaseDeposits.value), 3, 0)
          : '0',
        button:
          stats.withdrawRequests.value.endTime != 0 ? 'Pending...' : 'Withdraw',
        buttonVariant: 'pink',
        buttonClick: () => onWithdraw(),
        buttonDisabled:
          !stats.totalEaseDeposits ||
          Number(stats.totalEaseDeposits.value) == 0 ||
          stats.withdrawRequests.value.endTime != 0,
        icon: <LightningBoltIcon className={'w-8 h-8 text-blackop-30'} /> //,
        //tooltipTitle:
        //  'The amount of $EASE you deposited into the $gvEASE system. After deposit, these tokens can only be withdrawn after 7 days.'
      },
      {
        name: 'Total $gvEase',
        stat: stats.gvTokenBalanceOf
          ? formatDecimals(weiToEth(stats.gvTokenBalanceOf.value), 3, 0)
          : '0',
        button: '',
        buttonVariant: 'blackop',
        buttonClick: () => null,
        buttonDisabled:
          !stats.gvTokenBalanceOf || Number(stats.gvTokenBalanceOf.value) == 0,
        icon: <ClockIcon className={'w-8 h-8 text-blackop-30'} /> //,
        //tooltipTitle: 'This is the total amount of $gvEase you possess.'
      },
      {
        name: 'Pending rewards',
        stat: stats.easeEarned
          ? formatDecimals(weiToEth(stats.easeEarned.value), 3, 0)
          : '0',
        button: 'Compound',
        buttonVariant: 'pink',
        buttonClick: () => onClaimAndDepositRewards(),
        buttonDisabled:
          !stats.easeEarned || Number(stats.easeEarned.value) == 0,
        icon: <ClockIcon className={'w-8 h-8 text-blackop-30'} />,
        buttonSecondary: 'Claim',
        buttonSecondaryClick: () => onClaimRewards(),
        buttonSecondaryVariant: 'blackop',
        tooltipTitle: ''
      }
    ],
    [stats.easeBalance, stats.easeEarned, stats.gvTokenBalanceOf]
  )

  return (
    <div>
      <dl className="first-step grid grid-cols-1 mt-5 overflow-hidden divide-y divide-black rounded-lg shadow bg-blackop-50 md:grid-cols-4 md:divide-y-0 md:divide-x">
        {statsData.map((item, index) => (
          <div key={item.name} className={`px-4 py-5 sm:p-6 gvd-${index}`}>
            <div className={'flex items-center'}>
              <div className={'flex-grow'}>
                <dt className="flex text-base font-normal text-white gap-x-2">
                  {item.name}{' '}
                  {item.tooltipTitle && (
                    <Tooltip
                      // options
                      title={item.tooltipTitle}
                      position="top"
                      trigger="mouseenter"
                    >
                      <InformationCircleIcon
                        onClick={() => {}}
                        className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
                      />
                    </Tooltip>
                  )}
                </dt>
                <dd className="flex items-baseline justify-between mt-1 md:block">
                  <div className="flex items-baseline text-2xl font-semibold text-orange-500">
                    {item.stat}
                  </div>
                  <div className="flex justify-between">
                    {item.button != '' && (
                      <Button
                        onClick={item.buttonClick}
                        variant={item.buttonVariant}
                        disabled={item.buttonDisabled}
                        size={'xs'}
                        classes={'font-normal mt-2 '}
                      >
                        {item.button}
                      </Button>
                    )}
                    {item.buttonSecondary &&
                      item.buttonSecondaryClick &&
                      item.buttonSecondaryVariant && (
                        <>
                          <Button
                            onClick={item.buttonSecondaryClick}
                            variant={item.buttonVariant}
                            disabled={item.buttonDisabled}
                            size={'xs'}
                            classes={'font-normal mt-2 '}
                          >
                            {item.buttonSecondary}
                          </Button>
                        </>
                      )}
                  </div>
                </dd>
              </div>
            </div>
          </div>
        ))}
      </dl>
      <LoadingModal
        open={loadingModalOpen}
        title={loadingModalTitle}
        onClose={() => {}}
      >
        {loadingModalBody}
      </LoadingModal>

      {amountModalObj.isOpen && (
        <AmountModal
          title={amountModalObj.title}
          confirmText={amountModalObj.buttonText}
          onConfirm={() => amountModalObj.onConfirm()}
          onClose={() =>
            setAmountModalObj((v) => {
              v.isOpen = false
              return v
            })
          }
          maxAmount={amountModalObj.maxAmount}
          onChange={(e: any) => {
            amountModalRef.current = e.toString()
          }}
          open={amountModalObj.isOpen}
          symbol={amountModalObj.symbol}
        >
          <div className={'text-gray-600 mt-4 border-t border-gray-200'}>
            <div
              className={'p-4 text-sm flex justify-center gap-x-2 items-center'}
            >
              <InformationCircleIcon className={'h-4 w-4 text-gray-600'} />{' '}
              Withdrawals are delayed for 7 days.
            </div>
          </div>
        </AmountModal>
      )}

      <ConfirmationModal
        title={
          <>
            Confirm deposit for{' '}
            <span className={'text-orange-500'}>
              {amountModalRef.current &&
                formatDecimals(Number(amountModalRef.current), 4, 2)}
            </span>{' '}
            $Ease
          </>
        }
        confirmText={'Deposit'}
        onConfirm={() => onDepositConfirm()}
        onClose={() => setConfirmationModalOpen(false)}
        open={confirmationModalOpen}
      >
        <div className="mt-4">
          <p className="text-sm text-gray-500">
            Withdrawals are delayed for 7 days.
          </p>
        </div>
      </ConfirmationModal>
    </div>
  )
}
