import { fromWei, isAddress } from 'web3-utils'
import BN from 'bn.js'
import Web3 from 'web3'
import { provider } from 'web3-core'
import { ContractInterface } from '../contracts'
import { formatUnits } from 'ethers/lib/utils'
import { BigNumber } from 'ethers'
import { BigNumberish } from '@ethersproject/bignumber/src.ts/bignumber'
import { setIsDevelopment } from '../store/globalSlice'

export const uglifyAddress = (address: string, x = 10, y = 8) => {
  return address
    ? `${address.substring(0, x)}...${address.substring(
        address.length - y,
        address.length
      )}`
    : null
}

export const capitalize = (val: string) => {
  if (val) {
    return val.charAt(0).toUpperCase() + val.slice(1)
  }

  return ''
}

export const isDevelopmentUrl = () => {
  // @ts-ignore
  return (
    window.location.href.includes('devapp.ease.org') ||
    window.location.href.includes('armorfi.vercel.app') ||
    process.env.NODE_ENV != 'production' // for vercel
  )
}

export const isValidAddress = (address: string) => {
  return isAddress(address)
}

export const copyrightYears = (baseYear = '2020') => {
  return new Date().getFullYear().toString() === baseYear.toString()
    ? new Date().getFullYear().toString()
    : `${baseYear.toString()}-${new Date().getFullYear().toString()}`
}

export const toEther = (wei: BN) => {
  // @ts-ignore
  return fromWei(wei, 'ether')
}

export const weiToEth = (wei: BigNumberish) => {
  return Number(formatUnits(BigNumber.from(wei), 18))
}

export const toEtherFormatted = (wei: BN, decimals: number) => {
  return commas(decimals).format(parseFloat(toEther(wei)))
}

export const commas = (digits: number) =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: digits
  })

export const makeWeb3Contract = (
  currentProvider: provider,
  contract: ContractInterface,
  address?: string
) => {
  const web3 = new Web3(currentProvider)
  // @ts-ignore - needed for extended abi items. the web3 library doesn't account for 'receive' type (see: contracts/dashboard.ts)
  return new web3.eth.Contract(
    contract.abi,
    address ? address : contract.address
  )
}

export const calculateConversion = (amount: number, conversion: number) => {
  return amount / conversion
}

export const getChainIdName = (chainId: number | undefined) => {
  switch (chainId) {
    case 1:
      return 'Ethereum Mainnet'
    case 3:
      return 'Ethereum Testnet Ropsten'
    case 4:
      return 'Ethereum Testnet Rinkeby'
    case 10:
      return 'Optimistic Ethereum'
  }

  return ''
}

export function countDecimals(value: string | number | null) {
  if (value == null) return 0
  if (typeof value == 'string') {
    value = parseFloat(value)
  }
  if (Math.floor(value) === value) return 0
  if (value.toString().split('.').length < 2) {
    return 0
  }
  return value.toString().split('.')[1].length || 0
}

export function formatDecimals(
  value: number,
  maxDigits: number,
  minDigits: number = 0
): string {
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits
  }).format(value)
}

export function isNumeric(val: string) {
  return /^[\d+.]+$/.test(val)
}

export function formatCommas(value: number): string {
  return Intl.NumberFormat('en-US', {}).format(value)
}

export const runOnClient = (func: () => any) => {
  if (typeof window !== 'undefined') {
    if (window.document.readyState == 'loading') {
      window.addEventListener('load', func)
    } else {
      func()
    }
  }
}

export const parseWeb3Error = (error: Error): [string, any] => {
  let message = error.message
  let jsonError = { transactionHash: undefined, reason: undefined }
  if (message.includes(':\n{')) {
    message = message.substring(0, message.indexOf(':\n{'))
    try {
      jsonError = JSON.parse(
        error.message.substring(error.message.indexOf('\n'))
      )
    } catch {}
  }

  return [message, jsonError]
}

function roundDown(num: number) {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10)
}

export function formatShort(num: number) {
  if (num > 999 && num < 1000000) {
    return roundDown(num / 1000) + 'K' // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000 && num < 1000000000) {
    return roundDown(num / 1000000) + 'M' // convert to M for number from > 1 million
  } else if (num > 1000000000) {
    return roundDown(num / 1000000000) + 'B'
  }

  return formatDecimals(num, 2, 2)
}

export function unifiedFormat(_num: number | string) {
  let num = Number(_num)
  if (num > 999 && num < 1000000) {
    return roundDown(num / 1000) + 'K' // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000 && num < 1000000000) {
    return roundDown(num / 1000000) + 'M' // convert to M for number from > 1 million
  } else if (num > 1000000000) {
    return roundDown(num / 1000000000) + 'B'
  }
  return flexibleDecimals(num)
}

export const flexibleDecimals = (_num: string | number) => {
  let checkDecimal = 1
  const relevantDigits = (num: number) => {
    while (checkDecimal * Math.abs(num) < 1) {
      checkDecimal = 10 * checkDecimal
      if (checkDecimal * Math.abs(num) >= 1) {
        return num.toFixed(checkDecimal.toString().length + 1)
      }
    }
  }

  let formateNum = Number(_num)
  if (formateNum === 0) {
    return formateNum.toString()
  } else if (formateNum >= 1000 || formateNum <= -1000) {
    return formateNum.toFixed(0) // 0 decimals
  } else if (formateNum >= 100 || formateNum <= -100) {
    return formateNum.toFixed(0) // 0 decimals
  } else if (formateNum >= 1 || formateNum <= -1) {
    return formateNum.toFixed(2) // 2 decimals
  } else if (formateNum < 1 && formateNum > -1) {
    return relevantDigits(formateNum) // always 3 relevant digits after zeros
  }
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function shrink(text: string, max: number): string {
  if (text.length > max) {
    return text.substring(0, max) + '...'
  }

  return text
}

export function shrinkSplit(text: string, eachSide: number): string {
  if (text.length > eachSide) {
    return (
      text.substring(0, eachSide) +
      '...' +
      text.substring(text.length - eachSide)
    )
  }

  return text
}

export const scrollToTop = () => {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

export function makeDecimalUnits(decimals: number): string {
  let units = '1'
  for (let i = 1; i <= decimals; i++) {
    units += '0'
  }
  return units
}

export function formatDelimiter(commasValue: string): string {
  const items = commasValue.split(',')
  let result = ''
  for (let i = 0; i < items.length; i++) {
    result += items[i]
  }
  return result
}

export function formatInfo(info: string, info_link: string): string {
  const firstStr = info.split('[')[0]
  const secondStr = info.split('[')[1]
  const link = `${firstStr}
      <a href="${info_link}" target="_blank" class="text-white underline underline-offset-2">${
    secondStr.split(']')[0]
  }</a>
    ${secondStr.split(']')[1]}`
  return link
}

export function chainIdToName(chainId: number): string {
  switch (chainId) {
    case 3:
      return 'Ethereum Ropsten Testnet'
    case 4:
      return 'Ethereum Rinkeby Testnet'
    case 5:
      return 'Ethereum Goerli Testnet'
    case 42:
      return 'Ethereum Kovan Testnet'
    case 6:
      return 'Ethereum Classic Kotti Testnet'
    case 61:
      return 'Ethereum Classic Mainnet'
    case 63:
      return 'Ethereum Ropsten Mordor Testnet'
    case 212:
      return 'Ethereum Ropsten Astor Testnet'
    case 137:
      return 'Matic Mainnet'
    case 56:
      return 'Binance Smart Chain'
  }
  return 'the wrong network'
}

const _mergeArrays = <T>(a: Array<T>, b: Array<T>): Array<T> => {
  const c = []

  while (a.length && b.length) {
    c.push(a[0] > b[0] ? b.shift() : a.shift())
  }

  //if we still have values, let's add them at the end of `c`
  while (a.length) {
    c.push(a.shift())
  }
  while (b.length) {
    c.push(b.shift())
  }

  return c as Array<T>
}

export const mergeSort = <T>(a: Array<T>): Array<T> => {
  if (a.length < 2) return a
  const middle = Math.floor(a.length / 2)
  const a_l = a.slice(0, middle)
  const a_r = a.slice(middle, a.length)
  const sorted_l = mergeSort(a_l)
  const sorted_r = mergeSort(a_r)

  return _mergeArrays(sorted_l, sorted_r)
}
