import { useQuery } from 'react-query'

const getVaults = async () => {
  const response = await fetch(
    process.env.REACT_APP_BASE_URL + '/api/v1/vaults'
  )
  return response.json()
}

export default function useGetVaults() {
  return useQuery<VaultsApi.Vault[], Error>('vaults/get', () => getVaults(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 1
  })
}

export declare module VaultsApi {
  export interface Token {
    symbol: string
    name: string
    address: string
    assets: string
    display_name: string
    icon: string
    version: string
    decimals: number
    type: string
    apy: string
    price: string
    priceUSD: number
  }

  export interface Vault {
    symbol: string
    name: string
    address: string
    display_name: string,
    instruction: string,
    icon: string
    info: string
    info_link: string
    decimals: number
    type: string
    token: Token
    balance: string
    underlying_token_balance: string
    top_protocol: string
    protocols: string[]
    request: RequestOfStruct
    liquidation_amount: string
    liquidation_proof: string[]
    liquidation_root: string
    capacity: string
    protocol_type: string
    type_link: string
    remaining_capacity: string
    remaining_capacity_usd: string
    maxFee: number
  }

  export interface RequestOfStruct {
    uAmount: string
    rcaAmount: string
    endTime: string
  }
}

export const defaultRequestOf: () => VaultsApi.RequestOfStruct = () => {
  return { uAmount: '0', rcaAmount: '0', endTime: '0' }
}
