import { Step } from 'react-joyride'

export const farmingTabSteps: Array<Step> = [
  {
    target: '.farming-tab',
    placement: 'top',
    content:
      'This functionality will be added in the upcoming version of Uninsurance.',
    disableBeacon: true
  }
]
