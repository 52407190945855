import React from 'react'
import DiscordIcon from './icons/DiscordIcon'
import TwitterIcon from './icons/TwitterIcon'
import RedditIcon from './icons/RedditIcon'
import GithubIcon from './icons/GithubIcon'
import LinkedInIcon from './icons/LinkedInIcon'
import ForumIcon from './icons/ForumIcon'

export const Footer: React.FC<{}> = ({}) => {
  const copyRightDate =  new Date()
  const navigation = {
    main: [
      {
        name: 'Contact Us',
        href: 'https://ease.org/about-ease-defi/contact-the-ease-team/'
      },
      { name: 'About', href: 'https://ease.org/about-ease-defi/' },
      { name: 'Learn', href: 'https://ease.org/learn/' },
      { name: 'Get Involved', href: 'https://ease.org/get-involved/' }
    ],
    social: [
      {
        name: 'Discord',
        href: 'https://discord.gg/9JVTdFXdgF',
        icon: (props: React.PropsWithChildren<any>) => (
          <DiscordIcon {...props} />
        )
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/easeDeFi',
        icon: (props: React.PropsWithChildren<any>) => (
          <TwitterIcon {...props} />
        )
      },
      {
        name: 'Forum',
        href: 'https://forum.ease.org/',
        icon: (props: React.PropsWithChildren<any>) => (
          <ForumIcon classes={'h-4'} {...props} />
        )
      },
      {
        name: 'Reddit',
        href: 'https://www.reddit.com/r/easeDeFi',
        icon: (props: React.PropsWithChildren<any>) => <RedditIcon {...props} />
      },
      {
        name: 'Github',
        href: 'https://github.com/easeDeFi',
        icon: (props: React.PropsWithChildren<any>) => <GithubIcon {...props} />
      },
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/easeDeFi',
        icon: (props: React.PropsWithChildren<any>) => (
          <LinkedInIcon {...props} />
        )
      }
    ]
  }

  return (
    <footer className="bg-white bottom-0 w-full">
      <div className="max-w-7xl mx-auto py-2 md:py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a
                href={item.href}
                className="text-base text-gray-500 hover:text-gray-900"
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-4 md:mt-8 flex flex-wrap gap-y-2 justify-center space-x-6">
          {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-600 flex items-center gap-x-1"
              target={'_blank'}
              rel={'nofollow noreferrer'}
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
              <span className={'hidden md:flex'}>{item.name}</span>
            </a>
          ))}
        </div>
        <p className="mt-4 md:mt-8 text-center text-base text-gray-400">
          &copy; {copyRightDate.getFullYear()} Ease.org. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
