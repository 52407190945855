import { Step } from 'react-joyride'

export const tabPanelsSteps: Array<Step> = [
  {
    target: '.tab-panels',
    placement: 'top',
    content:
      'If you click on a vault’s name, it will open up to show the following 3 tabs: Deposit, Farming, and Info',
    disableBeacon: true
  }
]
