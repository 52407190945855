import React, { useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { SubTitle } from '../SubTitle'
import { ActiveDepositRow } from './ActiveDepositRow'
import { VaultsApi } from '../../../hooks/api/useGetVaults'
import { ArrowIcon } from '../../icons/ArrowIcon'
import { formatUnits } from 'ethers/lib/utils'
import { WalletConnect } from '../../WalletConnect'
import {
  sanitizeApys,
  sortByApy,
  sortByPrice,
  sortByRcaBalance,
  sortBySymbol,
  sortByTotalAssets,
  sortByValue,
  sortByMaxFee
} from '../../../utils/vaults'
import { BigNumber } from 'ethers'

export const ActiveDeposits: React.FC<{
  vaults: VaultsApi.Vault[]
  onUpdate: Function
  withdrawalHours: string
}> = ({ vaults, withdrawalHours, onUpdate }) => {
  const { account } = useWeb3React()
  const [column, setColumn] = useState('Name')
  const [sortByType, setSortByType] = useState('APY')
  const [sortDirection, setSortDirection] = useState('desc')
  const [isModalOpened, setIsModalOpened] = useState(false)
  const setSortBy = (column: string = 'Name') => {
    if (column == sortByType) {
      if (sortDirection == 'asc') {
        setSortDirection('desc')
        return
      }

      setSortDirection('asc')
      return
    }

    setSortDirection('desc')
    setSortByType(column)
  }

  const calcTotalAssets = (vault: VaultsApi.Vault) => {
    return BigNumber.from(vault.token.assets).mul(
      BigNumber.from((Number(vault.token.priceUSD) * 100).toFixed(0))
    )
  }

  const sortedVaults = useMemo(() => {
    let sorted = [...vaults]

    switch (sortByType) {
      case 'Name':
        return sortBySymbol(sorted, sortDirection)
      case 'APY':
        return sortByApy(sanitizeApys(sorted), sortDirection)
      case 'Total Assets':
        return sortByTotalAssets(sorted, sortDirection)
      case 'Max Fee':
        return sortByMaxFee(sorted, sortDirection)
      case 'Balance':
        return sortByRcaBalance(sorted, sortDirection)
      case 'Value':
        return sortByValue(sorted, sortDirection)
      case '':
      default:
        return vaults
    }
  }, [calcTotalAssets, vaults, sortDirection, sortByType])

  return (
    <div>
      <div className={'mt-6 text-lg text-white font-bold tracking-wide'}>
        <SubTitle
          title={'Your Active Deposits'}
          classes={'px-2 md:px-none'}
          tooltip={
            'A list of Uninsurance vaults you are currently contributing assets to.'
          }
        />
      </div>

      <div
        className={
          'w-full grid grid-cols-5 md:grid-cols-8 rounded-2xl mt-4 font-poppins text-sm mb-4 md:mb-none'
        }
      >
        <div
          className={
            'text-white col-span-3 flex gap-x-1 items-center cursor-pointer px-2 md:px-none'
          }
          onClick={() => setSortBy('Name')}
        >
          Name{' '}
          <ArrowIcon
            selected={sortByType == 'Name'}
            sortDirection={sortDirection}
          />
        </div>
        <div
          className={'text-white flex gap-x-1 items-center cursor-pointer'}
          onClick={() => setSortBy('APY')}
        >
          APY{' '}
          <ArrowIcon
            selected={sortByType == 'APY'}
            sortDirection={sortDirection}
          />
        </div>
        <div
          className={
            'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
          }
          onClick={() => setSortBy('Max Fee')}
        >
          Max Fee{' '}
          <ArrowIcon
            selected={sortByType == 'Max Fee'}
            sortDirection={sortDirection}
          />
        </div>
        <div
          className={
            'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
          }
          onClick={() => setSortBy('Balance')}
        >
          Balance{' '}
          <ArrowIcon
            selected={sortByType == 'Balance'}
            sortDirection={sortDirection}
          />
        </div>
        <div
          className={
            'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
          }
          onClick={() => setSortBy('Value')}
        >
          Value{' '}
          <ArrowIcon
            selected={sortByType == 'Value'}
            sortDirection={sortDirection}
          />
        </div>
      </div>

      <div className={'md:mt-4 rounded-2xl bg-blackop-50 mx-2 md:mx-none'}>
        {sortedVaults &&
          sortedVaults.map((vault, index) => (
            <ActiveDepositRow
              key={`${vault.address}${vault.symbol}${index}`}
              vault={vault}
              withdrawalDelay={withdrawalHours}
              onUpdate={onUpdate}
              index={index}
              total={sortedVaults.length}
            />
          ))}
        {account && sortedVaults.length == 0 && (
          <div
            className={
              'p-4 text-white text-center text-lg flex flex-col items-center'
            }
          >
            No Active Deposits
          </div>
        )}
        {!account && (
          <div
            className={
              'p-4 text-white text-center text-lg flex flex-col items-center'
            }
          >
            Please connect your wallet to display your active deposits
            <div
              className="inline-flex items-center px-3 py-1 mt-4 text-sm font-medium font-bold text-white uppercase bg-pink-500 border border-transparent rounded-md shadow-sm cursor-pointer hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              onClick={() => setIsModalOpened(true)}
            >
              Connect
            </div>
          </div>
        )}
      </div>
      <WalletConnect
        onClose={() => setIsModalOpened(false)}
        open={isModalOpened}
      />
    </div>
  )
}
