import { Tab } from '@headlessui/react'
import { classNames, formatDecimals, isNumeric } from '../../classes/helpers'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { VaultsApi } from '../../hooks/api/useGetVaults'
import { toWei } from 'web3-utils'
import { useWeb3React } from '@web3-react/core'
import Contracts from '../../contracts'
import useContractMethodSend from '../../hooks/useContractMethodSend'
import { LoadingModal } from '../LoadingModal'
import moment from 'moment'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { Button } from '../Button'
import useContractMethodCall from '../../hooks/useContractMethodCall'
import BN from 'bn.js'
import { BigNumber, FixedNumber } from 'ethers'

export const WithdrawTabPanel: React.FC<{
  children?: React.PropsWithChildren<any>
  withdrawalDelay: string
  onUpdate: Function
  vault: VaultsApi.Vault
}> = ({ children, vault, withdrawalDelay, onUpdate }) => {
  const { account } = useWeb3React()
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState('Requesting Withdrawal...')
  const [loadingText, setLoadingText] = useState<ReactNode | string>('')
  const memoizedBalance = useMemo(() => {
    return formatUnits(vault.balance, vault.decimals)
  }, [vault.balance, vault.decimals])
  const [withdrawValue, setWithdrawValue] = useState(memoizedBalance)

  const [uValue, getUValue] = useContractMethodCall<number>(
    Contracts.rcaShield,
    'uValue',
    new BN('0'),
    vault.address
  )
  const [percentReserved, getPercentReserved] = useContractMethodCall<number>(
    Contracts.rcaShield,
    'percentReserved',
    new BN('0'),
    vault.address
  )

  useEffect(() => {
    if (account) {
      getPercentReserved()
        .catch((err) => console.error({ err }))
        .then(() => {
          getUValue([toWei('1'), 0, percentReserved]).catch((err) =>
            console.error({ err })
          )
        })
    }
  }, [account])
  // smart contract callbacks and event handlers
  const onError = (err: Error) => {
    console.error(err)
    setLoadingModalOpen(false)
  }
  const onRedeemSuccess = () => {
    setLoadingModalOpen(false)
    onUpdate()
  }
  const redeemRequest = useContractMethodSend({
    contract: Contracts.rcaShield,
    methodName: 'redeemRequest',
    onSuccess: onRedeemSuccess,
    onError: onError,
    address: vault.address
  })
  const _redeemRequest = async (max: boolean = false) => {
    let value = toWei(withdrawValue.toString())
    let display = withdrawValue
    if (max) {
      value = vault.balance
      display = memoizedBalance
    }

    setLoadingTitle('Requesting Withdrawal...')
    setLoadingText(
      <>
        Requesting a withdrawal for{' '}
        <span className={'font-medium text-orange-500'}>{display}</span>{' '}
        {vault.token.symbol}
      </>
    )
    setLoadingModalOpen(true)
    await redeemRequest([value, 0, [], 0, []]).catch((err) =>
      console.error(err)
    )
  }

  const redeemFinalize = useContractMethodSend({
    contract: Contracts.rcaShield,
    methodName: 'redeemFinalize',
    onSuccess: onRedeemSuccess,
    onError: onError,
    address: vault.address
  })
  const _redeemFinalize = async () => {
    setLoadingTitle('Finalizing Withdrawal...')
    setLoadingText(
      <>
        Finalizing withdrawal for{' '}
        <span className={'font-medium text-orange-500'}>
          {memoizedRedeemBalance}
        </span>{' '}
        {vault.token.symbol}
      </>
    )
    setLoadingModalOpen(true)
    await redeemFinalize([account, '0x', 0, [], 0, []]).catch((err) =>
      console.error(err)
    )
  }

  const memoizedRedeemBalance = useMemo(() => {
    return Number(formatUnits(vault.request.rcaAmount, vault.decimals))
  }, [vault.request.rcaAmount, vault.decimals])

  const hasValidWithdrawValue = () => isNumeric(withdrawValue.toString())

  const isPendingWithdraw = () => Number(vault.request.uAmount) > 0
  const isAvailableToWithdraw = () =>
    moment(Number(vault.request.endTime.toString()) * 1000).isBefore(moment())

  // lower deposit value by 0.01% to mitigate rounding issues
  const isAboveMaximumBalance = () =>
    Number(memoizedBalance) >=
    Number(withdrawValue) - Number(withdrawValue) * 0.01

  const isWithdrawDisabled = () =>
    !account || !hasValidWithdrawValue() || isAboveMaximumBalance()

  const dollarValue = () =>
    vault.token.priceUSD
      ? formatDecimals(
          Number(vault.token.priceUSD) * parseFloat(withdrawValue),
          2
        )
      : 0

  const withdrawAmountAfterConversion = () => {
    if (uValue != 0 && withdrawValue) {
      let wei = toWei(withdrawValue)

      let uval = BigNumber.from(uValue)
      let result = uval.mul(BigNumber.from(wei))

      return formatUnits(result, 36)
    }

    return BigNumber.from('0')
  }

  return (
    <Tab.Panel
      className={classNames(
        'text-white rounded-xl py-4 px-1 py-4 px-1 panel-sm mx-auto',
        'focus:outline-none focus:ring-transparent'
      )}
    >
      {isPendingWithdraw() && (
        <div className={'text-center'}>
          <div
            className={
              'mb-3 font-poppins flex gap-x-2 justify-center items-center'
            }
          >
            <div>
              Withdrawal{' '}
              {isAvailableToWithdraw() ? <>available</> : <>pending</>} for{' '}
            </div>
            <div
              className={
                'font-medium text-white bg-blackop-50 px-2 py-1 rounded flex items-center'
              }
            >
              {memoizedRedeemBalance}
            </div>{' '}
            <div>{vault.token.symbol}</div>
          </div>
          {!isAvailableToWithdraw() && (
            <div>
              Your withdrawal is available on{' '}
              <span className={'font-bold'}>
                {moment(Number(vault.request.endTime.toString()) * 1000).format(
                  'MMMM Do YYYY, h:mm:ss a'
                )}
              </span>
            </div>
          )}
          <div
            className={'flex w-full justify-center items-end gap-x-3 mt-5 mb-5'}
          >
            <Button
              disabled={!isAvailableToWithdraw()}
              onClick={() => _redeemFinalize()}
              block
            >
              Finalize Withdraw
            </Button>
          </div>
        </div>
      )}
      {!isPendingWithdraw() && (
        <div
          className={
            'flex justify-center items-center gap-y-2 flex-col gap-x-3'
          }
        >
          <div className="bg-blackop-50 w-full rounded-xl px-3">
            <div className="flex justify-between pt-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                From
              </span>
              <span
                className="text-gray-500 sm:text-sm cursor-pointer"
                id="price-currency"
                onClick={() => setWithdrawValue(memoizedBalance)}
              >
                Balance{' '}
                {memoizedBalance
                  ? formatDecimals(Number(memoizedBalance), 10)
                  : memoizedBalance}
              </span>
            </div>
            <div className="flex justify-between mt-2 pb-5">
              <div className={'flex gap-x-2 col-span-2 items-center'}>
                <img
                  src={vault.icon}
                  className={'h-6 w-6 rounded-full'}
                  alt={'multichain'}
                />
                {vault.symbol}
              </div>
              <div className="text-right">
                <div className="relative shadow-sm">
                  <input
                    type="text"
                    name="price"
                    id="price"
                    value={withdrawValue}
                    onChange={(e) => {
                      setWithdrawValue(() => e.target.value)
                    }}
                    className="max-w-xs bg-blackop-50 font-medium text-white text-right border-transparent focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-3xl rounded-2xl"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                  />
                </div>
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  ≈ ${dollarValue()}
                </span>
              </div>
            </div>
          </div>
          <div className="bg-blackop-50 w-full rounded-xl px-3 mt-4">
            <div className="flex justify-between pt-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                To
              </span>
            </div>
            <div className="flex justify-between mt-3 pb-5">
              <div className={'flex gap-x-2 col-span-2 items-center'}>
                <img
                  src={vault.icon}
                  className={'h-6 w-6 rounded-full'}
                  alt={'multichain'}
                />
                {vault.token.symbol}
              </div>
              <span className="font-medium sm:text-3xl text-xl">
                {Number(withdrawValue) > 0
                  ? formatDecimals(Number(withdrawAmountAfterConversion()), 10)
                  : withdrawValue}
              </span>
            </div>
          </div>
          <div className={'flex w-full justify-center items-end gap-x-3 mt-4'}>
            <Button
              disabled={!isWithdrawDisabled()}
              onClick={() => _redeemRequest(true)}
              block
            >
              Withdraw Max
            </Button>

            <Button
              disabled={!isWithdrawDisabled()}
              onClick={() => _redeemRequest()}
              block
            >
              Withdraw
            </Button>
          </div>
          <div className={'mt-1'}>
            Your withdrawal will be available after {withdrawalDelay.toString()}
          </div>
        </div>
      )}
      {/*<div>reserved: {percentReserved}</div>*/}
      {/*<div>uvalue: {uValue}</div>*/}
      <LoadingModal
        open={loadingModalOpen}
        title={loadingTitle}
        onClose={() => {}}
      >
        <div
          className={
            'pt-8 mb-4 border-t border-gray-100 text-center font-poppins text-gray-700'
          }
        >
          {loadingText}
        </div>
      </LoadingModal>
    </Tab.Panel>
  )
}
