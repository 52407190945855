import { Tab } from '@headlessui/react'
import {
  classNames,
  countDecimals, flexibleDecimals,
  formatDecimals,
  isNumeric,
  makeDecimalUnits, unifiedFormat
} from '../../classes/helpers'
import React, { useEffect, useMemo, useState } from 'react'
import { LoadingModal } from '../LoadingModal'
import { useWeb3React } from '@web3-react/core'
import useContractMethodCall from '../../hooks/useContractMethodCall'
import Contracts from '../../contracts'
import BN from 'bn.js'
import { toWei } from 'web3-utils'
import useContractMethodSend from '../../hooks/useContractMethodSend'
import { getPermits } from '../../hooks/api/useGetPermits'
import { LoadingModalSteps, ModalStep } from '../LoadingModalSteps'
import { ConfirmationModal } from '../ConfirmationModal'
import { VaultsApi } from '../../hooks/api/useGetVaults'
import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { Button } from '../Button'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { notifyError } from '../Layout'
import { useGetUnderlyingTokenBalance } from '../../hooks/dashboard/useGetUnderlyingTokenBalance'
import { useGetEzTokenBalance } from '../../hooks/dashboard/useGetEzTokenBalance'

enum Steps {
  APPROVE = 1,
  INIT_DEPOSIT = 2,
  MINT_TO = 3
}

export const DepositTabPanel: React.FC<{
  children?: React.PropsWithChildren<any>
  withdrawalDelay: string
  onUpdate: Function
  vault: VaultsApi.Vault
}> = ({ children, vault, withdrawalDelay, onUpdate }) => {
  const { account, chainId } = useWeb3React()
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [tosAgreementModalOpen, setTosAgreementModalOpen] = useState(false)
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(Steps.APPROVE)
  const [depositValue, setDepositValue] = useState('0')
  const [depositMax, setDepositMax] = useState(false)
  const [agreedToTOS, setAgreedToTOS] = useLocalStorage<boolean>(
    'TOS_AGREEMENT',
    false
  )

  const [underlyingTokenBalance, getUnderlyingTokenBalance] =
    useGetUnderlyingTokenBalance(vault.token?.address ?? '0x')

  const [ezTokenBalance, getEzTokenBalance] = useGetEzTokenBalance(
    vault.token?.address ?? '0x'
  )

  const [, getNonce] = useContractMethodCall<number>(
    Contracts.rcaController,
    'nonces',
    new BN('0')
  )
  const [rcaValue, getRcaValue] = useContractMethodCall<number>(
    Contracts.rcaShield,
    'rcaValue',
    new BN('0'),
    vault.address
  )
  const [_, getAllowance] = useContractMethodCall<number>(
    Contracts.underlyingToken,
    'allowance',
    new BN('0'),
    vault.token?.address ?? '0x'
  )

  // smart contract callbacks and event handlers
  const onError = (err: Error) => {
    setLoadingModalOpen(false)
  }

  const onApprovalSuccess = () => {
    setCurrentStep(Steps.INIT_DEPOSIT)
    _mintTo().catch((err) => console.error(err))
  }
  const approve = useContractMethodSend({
    contract: Contracts.underlyingToken,
    methodName: 'approve',
    onSuccess: onApprovalSuccess,
    onError: onError,
    address: vault.token?.address ?? '0x'
  })

  const onMintToSuccess = () => {
    setLoadingModalOpen(false)
    onUpdate()
    updateStats()
  }
  const mintTo = useContractMethodSend({
    contract: Contracts.rcaShield,
    methodName: 'mintTo',
    onSuccess: onMintToSuccess,
    onError: onError,
    address: vault.address
  })

  const updateStats = () => {
    setIsLoading(true)
    Promise.all([
      getUnderlyingTokenBalance([account]).catch((err) =>
        console.error({ err })
      ),
      getEzTokenBalance([account]).catch((err) => console.error({ err })),
      getNonce([account]).catch((err) => console.error({ err })),
      getRcaValue([toWei('1'), 0]).catch((err) => console.error({ err }))
    ])
      .then(() => {
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.error(err)
      })
  }

  // hooks for rendering
  useEffect(() => {
    if (account) {
      updateStats()
    }
  }, [account])

  // smart contract button click handlers
  const _approve = async () => {
    setConfirmationModalOpen(false)
    setCurrentStep(Steps.APPROVE)

    // workaround for back-to-back scrollbar bug with @headlessui/modal
    // issue: https://github.com/tailwindlabs/headlessui/issues/1000#issuecomment-1001841999
    setTimeout(() => {
      setLoadingModalOpen(true)
    }, 500)

    let allowance = await getAllowance([account, vault.address]).catch((err) =>
      console.error(err)
    )

    if (!allowance) {
      allowance = 0
    }

    let wei = depositingWei()
    if (BigNumber.from(underlyingTokenBalance).lt(wei)) {
      wei = BigNumber.from(underlyingTokenBalance)
    }

    if (BigNumber.from(allowance) < wei) {
      await approve([vault.address, wei.toString()]).catch((err) =>
        console.error(err)
      )
    } else {
      setCurrentStep(Steps.INIT_DEPOSIT)
      _mintTo().catch((err) => console.error(err))
    }
  }
  const _mintTo = async () => {
    const _nonce = await getNonce([account]).catch((err) =>
      console.error({ err })
    )

    let wei = depositingWei()
    let balance = parseUnits(
      underlyingTokenBalance.toString(),
      18 - vault.token.decimals
    )
    if (balance.lt(wei)) {
      wei = balance
    }

    const permits = await getPermits({
      amount: wei.toString(),
      chainId: chainId ? chainId.toString() : '',
      nonce: _nonce ? _nonce.toString() : '0',
      user: account ?? '',
      vault: vault.address
    }).catch((err) => {
      console.error('permits error', err)
    })

    if (!permits || permits?.error) {
      notifyError(
        'Error while depositing',
        <div className={'capitalize'}>{permits?.error}</div>
      )
      setLoadingModalOpen(false)
      return
    }

    setCurrentStep(Steps.MINT_TO)

    await mintTo([
      account,
      vault.address,
      wei.toString(),
      permits.expiry,
      permits.vInt,
      permits.r,
      permits.s,
      toWei('0'),
      vault.liquidation_proof
    ]).catch((err) => console.error(err))
  }

  const memoizedUnderlyingTokenBalance = useMemo(
    () =>
      underlyingTokenBalance /
      Number(makeDecimalUnits(vault.token?.decimals ?? 18)),
    [underlyingTokenBalance, vault.token?.decimals]
  )
  const memoizedEzTokenBalance = useMemo(
    () => ezTokenBalance / Number(makeDecimalUnits(vault.decimals)),
    [ezTokenBalance, vault.decimals]
  )

  const hasValidDepositValue = () => isNumeric(depositValue.toString())

  const hasBalance = () => memoizedUnderlyingTokenBalance >= 0.000000001

  useEffect(() => {
    let balance = memoizedUnderlyingTokenBalance
    if (balance > Number(vault.remaining_capacity)) {
      balance = Number(vault.remaining_capacity)
    }

    setDepositValue(() => (hasBalance() ? flexibleDecimals(balance) ?? '0' : '0'))
  }, [memoizedUnderlyingTokenBalance])

  const depositingAmount = () => {
    if (memoizedUnderlyingTokenBalance < Number(depositValue) || depositMax) {
      return memoizedUnderlyingTokenBalance
    }

    return depositValue
  }

  const depositingWei = () => {
    let wei = parseUnits(depositValue.toString(), 'ether')

    let balance = parseUnits(
      underlyingTokenBalance.toString(),
      18 - vault.token?.decimals
    )

    if (depositMax) {
      let rc = vault.remaining_capacity.toString()
      let rc_split = rc.split('.')
      let rem_cap = parseUnits(rc_split[0], vault.token?.decimals)

      if (balance.lt(rem_cap)) {
        return balance
      }

      return rem_cap
    }

    if (BigNumber.from(balance).lt(wei)) {
      return balance
    }

    return wei
  }

  const steps = useMemo(
    (): ModalStep[] => [
      {
        name: 'Approve Transfer',
        description: `Approve ${formatDecimals(
          Number(depositingAmount()),
          10
        )} ${vault.token?.symbol} tokens to be deposited.`,
        status: currentStep == Steps.APPROVE ? 'current' : 'complete'
      },
      {
        name: 'Initializing Deposit',
        description: 'Getting information from our servers.',
        status:
          currentStep == Steps.INIT_DEPOSIT
            ? 'current'
            : currentStep < Steps.INIT_DEPOSIT
            ? 'upcoming'
            : 'complete'
      },
      {
        name: 'Deposit',
        description: `Deposit ${formatDecimals(
          Number(depositingAmount()),
          10
        )} ${vault.token?.symbol} and mint ${formatDecimals(
          (Number(depositingAmount()) * Number(rcaValue)) / Number(toWei('1')),
          10
        )} ${vault.symbol}`,
        status:
          currentStep == Steps.MINT_TO
            ? 'current'
            : currentStep < Steps.MINT_TO
            ? 'upcoming'
            : 'complete'
      }
    ],
    [
      depositValue,
      rcaValue,
      currentStep,
      vault?.symbol,
      vault?.token?.symbol,
      depositingAmount
    ]
  )

  const hasReachedCapacity = () =>
    Number(vault.remaining_capacity) == 0 ||
    Number(depositValue) > Number(vault.remaining_capacity)

  // lower deposit value by 0.01% to mitigate rounding issues
  const isAboveMaximumBalance = () =>
    memoizedUnderlyingTokenBalance <=
    Number(depositValue) - Number(depositValue) * 0.01

  const isDepositDisabled = () =>
    !hasBalance() ||
    !hasValidDepositValue() ||
    hasReachedCapacity() ||
    isAboveMaximumBalance()

  const isDepositMaxDisabled = () =>
    !hasBalance() || Number(vault.remaining_capacity) == 0
  // !hasValidDepositValue() ||
  // hasReachedCapacity() ||
  // isAboveMaximumBalance()

  const startDepositing = (max: boolean = false) => {
    if (!agreedToTOS) {
      setTosAgreementModalOpen(true)
      return
    }

    setDepositMax(max)
    setConfirmationModalOpen(true)
  }

  const agree = () => {
    setAgreedToTOS(true)
    setTosAgreementModalOpen(false)
    setTimeout(() => {
      setConfirmationModalOpen(true)
    }, 500)
  }

  const dollarValue = () =>
    vault.token?.priceUSD
      ? flexibleDecimals(
          Number(vault.token?.priceUSD ?? '0') * parseFloat(depositValue)
        )
      : 0

  return (
    <div data-testid={'deposit-tab-panel'}>
      <>
        <div className={isLoading ? 'filter blur-sm animate-pulse' : ''}>
          <div className="bg-blackop-50 rounded-xl px-3">
            <div className="flex justify-between pt-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                Deposit
              </span>
              <span
                className="text-gray-500 sm:text-sm cursor-pointer deposit-tab-balance"
                id="price-currency"
                data-testid={'deposit-tab-panel-balance'}
                onClick={() =>
                  setDepositValue(
                    hasBalance()
                      ? flexibleDecimals(memoizedUnderlyingTokenBalance) ?? '0'
                      : '0'
                  )
                }
              >
                Balance{' '}
                {flexibleDecimals(
                  memoizedUnderlyingTokenBalance
                )}
              </span>
            </div>
            <div className="flex justify-between mt-2 pb-5 gap-4">
              <div
                className={
                  'flex gap-x-2 col-span-2 items-center deposit-tab-token'
                }
              >
                <img
                  src={vault.icon}
                  className={'h-6 w-6 rounded-full'}
                  alt={vault.token?.symbol}
                />
                {vault.token?.symbol}
              </div>
              <div className="text-right">
                <div className="relative shadow-sm">
                  <input
                    type="text"
                    name="price"
                    id="price"
                    disabled={!hasBalance()}
                    value={depositValue}
                    onChange={(e) => {
                      setDepositValue(() => {
                        if (countDecimals(depositValue) > 10) {
                          return flexibleDecimals(e.target.value) ?? '0'
                        }
                        return e.target.value
                      })
                    }}
                    className={classNames(
                      !hasBalance() ? 'bg-gray-200 text-gray-500' : '',
                      'max-w-sm bg-blackop-50 font-medium text-white text-right border-transparent focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-3xl rounded-2xl'
                    )}
                    placeholder="0.00"
                    aria-describedby="price-currency"
                  />
                </div>
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  ≈ ${dollarValue()}
                </span>
              </div>
            </div>
          </div>
          <div className="bg-blackop-50 rounded-xl px-3 mt-4 deposit-tab-ez-token">
            <div className="flex justify-between pt-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                Receive
              </span>
              <span
                className="text-gray-500 sm:text-sm"
                id="price-currency"
                data-testid={'deposit-tab-panel-receive-balance'}
              >
                Balance{' '}
                {flexibleDecimals(
                  memoizedEzTokenBalance
                )}
              </span>
            </div>
            <div className="flex justify-between mt-3 pb-5">
              <div className={'flex gap-x-2 col-span-2 items-center'}>
                <img
                  src={vault.icon}
                  className={'h-6 w-6 rounded-full'}
                  alt={vault.symbol}
                />
                {vault.symbol}
              </div>
              <span className="font-medium text-xl sm:text-3xl">
                {formatDecimals(
                  (Number(rcaValue) * Number(depositValue)) /
                    Number(toWei('1')),
                  10
                )}
              </span>
            </div>
          </div>
          <div
            className={'mt-2 deposit-tab-remaining-capacity'}
            data-testid={'deposit-tab-panel-capacity'}
          >
            Capacity remaining:{' '}
            <span className={'font-medium px-1 rounded text-white'}>
              {unifiedFormat(vault.remaining_capacity ?? 0)}
            </span>
            <span className={'px-1 rounded text-white'}>
              [ USD:{' '}
              <span className={'font-medium'}>
                ${unifiedFormat(vault.remaining_capacity_usd ?? 0)}
              </span> ]
            </span>
          </div>
          <div
            className={
              'flex justify-center items-end gap-x-3 mt-3 mb-5 deposit-tab-call-to-action'
            }
          >
            <Button
              disabled={isDepositDisabled()}
              onClick={() => startDepositing()}
              block
              dataTestId={'deposit-tab-panel-deposit-button'}
            >
              Deposit
            </Button>

            <Button
              disabled={isDepositMaxDisabled()}
              onClick={() => startDepositing(true)}
              classes={'deposit-tab-deposit-max'}
              dataTestId={'deposit-tab-panel-deposit-max-button'}
              block
            >
              Deposit Max
            </Button>
          </div>
        </div>
      </>

      <ConfirmationModal
        title={
          <>
            Confirm deposit for{' '}
            <span className={'text-orange-500'}>
              {formatDecimals(Number(depositingAmount()), 10)}
            </span>{' '}
            {vault.token?.symbol}
          </>
        }
        confirmText={'Deposit'}
        onConfirm={() => _approve()}
        onClose={() => setConfirmationModalOpen(false)}
        open={confirmationModalOpen}
      >
        <div className="mt-4">
          <p className="text-sm text-gray-600">
            You will receive{' '}
            <span className={'font-bold'}>
              {formatDecimals(
                (Number(rcaValue) * Number(depositingAmount())) /
                  Number(toWei('1')),
                10
              )}
            </span>{' '}
            {vault.symbol}
          </p>
        </div>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Withdrawals are delayed for {withdrawalDelay}.
          </p>
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        title={<>Agreement Required</>}
        confirmText={'Yes, I Agree'}
        onConfirm={() => agree()}
        onClose={() => setTosAgreementModalOpen(false)}
        open={tosAgreementModalOpen}
      >
        <div className="mt-4">
          <p className="text-sm text-gray-500 mb-4">
            You are required to review the agreements and policies below before
            using the ease App.
          </p>
          <p className="text-sm text-gray-500">
            <a
              href={
                'https://ease.org/about-ease-defi/terms-and-conditions-disclaimer/'
              }
              target={'_blank'}
              rel={'nofollow noreferrer'}
              className={'text-gray-800 underline'}
            >
              Terms and Conditions
            </a>
          </p>
          <p className="text-sm text-gray-500">
            <a
              href={'https://ease.org/about-ease-defi/privacy-policy/'}
              target={'_blank'}
              rel={'nofollow noreferrer'}
              className={'text-gray-800 underline'}
            >
              Privacy Policy
            </a>
          </p>
          <p className="text-sm text-gray-500">
            <a
              href={
                'https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/ease-defi-cover/terms-of-ease-coverage/'
              }
              target={'_blank'}
              rel={'nofollow noreferrer'}
              className={'text-gray-800 underline'}
            >
              Terms of Coverage
            </a>
          </p>
        </div>
      </ConfirmationModal>

      <LoadingModal
        open={loadingModalOpen}
        title={`Depositing ${vault.token?.symbol}...`}
        onClose={() => {}}
      >
        <div className={'pt-8 mb-4 border-t border-gray-100'}>
          <LoadingModalSteps steps={steps} />
        </div>
      </LoadingModal>
    </div>
  )
}
