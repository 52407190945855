import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../components/PageTitle'
import useGetVaults, {
  defaultRequestOf,
  VaultsApi
} from '../hooks/api/useGetVaults'
import { capitalize, formatDecimals, scrollToTop } from '../classes/helpers'
import useContractMethodCall from '../hooks/useContractMethodCall'
import Contracts from '../contracts'
import BN from 'bn.js'
import { useWeb3React } from '@web3-react/core'
import { Opportunities } from '../components/dashboard/Opportunities/Opportunities'
import { ActiveDeposits } from '../components/dashboard/Deposits/ActiveDeposits'
import { BigNumber } from 'ethers'
import { PageLoading } from '../components/PageLoading'
import { useQueryClient } from 'react-query'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import { JoyrideTooltip } from '../components/dashboard/JoyrideTooltip'
import { useJoyrideSteps } from '../hooks/dashboard/useJoyrideSteps'
import { setOpenDepositPanel, setTabPanelIndex } from '../store/joyrideSlice'
import { AppDispatch } from '../store'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { depositTabSteps } from '../hooks/dashboard/depositTabSteps'
import { selectConfig, selectIsDevelopment } from '../store/globalSlice'
import { TenderlyRpcAlert } from '../components/dashboard/TenderlyRpcAlert'
import { Tooltip } from 'react-tippy'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { WrongChainAlert } from '../components/WrongChainAlert'
import { useImpersonatableWeb3React } from '../hooks/useImpersonatableWeb3React'
import { selectVaults } from '../store/vaultSlice'

export const Dashboard: React.FC<{}> = () => {
  // const { isLoading, isError, data: vaults, error } = useGetVaults()
  const vaults = useAppSelector(selectVaults)
  const [runJoyride, setRunJoyride] = useState(false)
  const queryClient = useQueryClient()
  const { account, chainId } = useImpersonatableWeb3React()
  const [stepIndex, setStepIndex] = useState(0)
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false)
  const { steps, setOverrideSteps } = useJoyrideSteps(account)
  const dispatch: AppDispatch = useAppDispatch()
  const globalConfig = useAppSelector(selectConfig)
  const showTenderlyRpcAlert = useAppSelector(selectIsDevelopment)
  const [joyrideSeenTour, setJoyrideSeenTour] = useLocalStorage(
    'joyride_seen_tour',
    false
  )

  useEffect(() => {
    if (!joyrideSeenTour) {
      setIsJoyrideRunning(true)
    }
  }, [])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type, step, lifecycle } = data

    // clicked "Not Now" or "Never" on intro
    if (type == EVENTS.STEP_AFTER && action == ACTIONS.CLOSE) {
      setIsJoyrideRunning(false)
      setJoyrideSeenTour(true)
      return
    }

    // automatically jump to the start of deposit tab when clicking previous from farming tab -- much cleaner feel
    if (
      type == EVENTS.STEP_AFTER &&
      action == ACTIONS.PREV &&
      step.target == '.farming-tab'
    ) {
      setStepIndex(findStepIndex('.deposit-tab'))
      dispatch(setTabPanelIndex(0))
      return
    }

    if (type == EVENTS.STEP_AFTER || type == EVENTS.TARGET_NOT_FOUND) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if (
      status == STATUS.FINISHED ||
      status == STATUS.SKIPPED ||
      status == STATUS.PAUSED
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      setIsJoyrideRunning(false)
      setJoyrideSeenTour(true)
      dispatch(setOpenDepositPanel(false))
      scrollToTop()
    }

    switch (step.target) {
      case '.tab-panels':
        dispatch(setOpenDepositPanel(true))
        break
      case '.deposit-tab':
      case '.deposit-tab-call-to-action':
        dispatch(setTabPanelIndex(0))
        break
      case '.farming-tab':
        dispatch(setTabPanelIndex(1))
        break
      case '.info-tab':
        dispatch(setTabPanelIndex(2))
        break
      case '.ease-app':
      case '.opportunities-sorting':
        scrollToTop()
        dispatch(setOpenDepositPanel(false))
        break
    }

    // console.groupCollapsed(type)
    // console.log(data) //eslint-disable-line no-console
    // console.groupEnd()
  }
  const findStepIndex = (target: string) => {
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].target === target) {
        return i
      }
    }

    return -1
  }

  const [activeDepositVaults, setActiveDepositVaults] = useState<
    VaultsApi.Vault[]
  >([])
  const [opportunityVaults, setOpportunityVaults] = useState<VaultsApi.Vault[]>(
    []
  )
  const [balanceOfs, getBalanceOfs, clearBalanceOfs] = useContractMethodCall<
    number[]
  >(Contracts.rcaController, 'balanceOfs', [])
  const [requestOfs, getRequestOfs, clearRequestOfs] = useContractMethodCall<
    VaultsApi.RequestOfStruct[]
  >(Contracts.rcaController, 'requestOfs', [])
  const [
    underlyingBalanceOfs,
    getUnderlyingBalanceOfs,
    clearUnderlyingBalanceOfs
  ] = useContractMethodCall<number[]>(Contracts.rcaController, 'balanceOfs', [])
  const [withdrawalDelay, getWithdrawalDelay] = useContractMethodCall<number>(
    Contracts.rcaController,
    'withdrawalDelay',
    new BN('0')
  )
  const withdrawalHours = useMemo(() => {
    let days = withdrawalDelay / 60 / 60 / 24
    return `${formatDecimals(days, 1)} day${days > 1 ? 's' : ''}`
  }, [withdrawalDelay])

  const updateBalances = () => {
    if (account) {
      if (!vaults) {
        return
      }

      let vaultAddresses = vaults.map((v) => v.address)
      let underlyingAddresses = vaults.map((v) => v.token.address)
      Promise.all([
        getRequestOfs([account, vaultAddresses]),
        getBalanceOfs([account, vaultAddresses]),
        getUnderlyingBalanceOfs([account, underlyingAddresses])
      ]).catch((err) => console.error(err))
    }
  }

  useEffect(() => {
    // console.log('vaults', vaults)
    updateBalances()
    if (!account) {
      if (!opportunityVaults || opportunityVaults.length == 0) {
        setEmptyOpportunityVaults()
      }
    }
  }, [vaults])

  useEffect(() => {
    if (account && chainId == 1) {
      updateBalances()
      Promise.all([getWithdrawalDelay([])]).catch((err) => console.error(err))
      setInterval(() => setRunJoyride(true), 2000)
    } else {
      setActiveDepositVaults([])
      Promise.all([
        clearBalanceOfs(),
        clearRequestOfs(),
        clearUnderlyingBalanceOfs(),
        queryClient.invalidateQueries('vaults/get')
      ]).then(() => {
        updateBalances()
      })
    }
  }, [account, chainId])

  useEffect(() => {
    if (
      vaults &&
      balanceOfs &&
      balanceOfs.length > 0 &&
      requestOfs &&
      requestOfs.length > 0 &&
      underlyingBalanceOfs &&
      underlyingBalanceOfs.length > 0
    ) {
      let _vaults = [...vaults]
      let _adVaults: VaultsApi.Vault[] = []
      _vaults.forEach((vault, index) => {
        let v = Object.assign({}, vault)
        v.balance = BigNumber.from(balanceOfs[index])
          .add(BigNumber.from(requestOfs[index]?.rcaAmount))
          .toString()

        v.underlying_token_balance = BigNumber.from(
          underlyingBalanceOfs[index]
        ).toString()

        if (
          requestOfs.length > index &&
          Number(requestOfs[index].uAmount) > 0
        ) {
          v.request = requestOfs[index]
        } else {
          v.request = defaultRequestOf()
        }
        _adVaults.push(v)
      })

      let _opVaults: VaultsApi.Vault[] = []
      _adVaults.forEach((vault, index) => {
        if (
          underlyingBalanceOfs &&
          underlyingBalanceOfs.length > 0 &&
          requestOfs &&
          requestOfs.length > 0
        ) {
          if (balanceOfs[index] == 0 && requestOfs[index].uAmount == '0') {
            let v = Object.assign({}, vault)
            v.balance = BigNumber.from(underlyingBalanceOfs[index]).toString()
            _opVaults.push(v)
          }
        } else {
          let v = Object.assign({}, vault)
          v.balance = '0'
          _opVaults.push(v)
        }
      })

      setOpportunityVaults(() => _adVaults)

      let _activeDepositVaults: VaultsApi.Vault[] = []
      _adVaults.forEach((vault, index) => {
        if (vault.balance != '0' || vault.request.uAmount != '0') {
          let v = Object.assign({}, vault)
          _activeDepositVaults.push(v)
        }
      })

      setActiveDepositVaults(() => _activeDepositVaults)
    } else {
      setEmptyOpportunityVaults()
    }
  }, [vaults, balanceOfs, requestOfs, underlyingBalanceOfs])

  const setEmptyOpportunityVaults = () => {
    if (vaults) {
      let _vaults = [...vaults]
      let _adVaults: VaultsApi.Vault[] = []
      _vaults.forEach((vault, index) => {
        let v = Object.assign({}, vault)
        v.balance = '0'
        v.underlying_token_balance = '0'
        v.request = defaultRequestOf()
        _adVaults.push(v)
      })

      let _opVaults: VaultsApi.Vault[] = []
      _adVaults.forEach((vault, index) => {
        let v = Object.assign({}, vault)
        v.balance = '0'
        _opVaults.push(v)
      })

      setOpportunityVaults(() => _opVaults)
    }
  }

  const memoizedProtocols = useMemo(() => {
    let _protocols = ['All Protocols']
    if (vaults) {
      vaults.forEach((v) => {
        if (!_protocols.includes(capitalize(v.top_protocol))) {
          _protocols.push(capitalize(v.top_protocol))
        }
      })
    }
    return _protocols
  }, [vaults])

  const topVaults = useMemo(() => {
    let _topVaults: VaultsApi.Vault[] = []
    if (opportunityVaults) {
      memoizedProtocols.forEach((p) => {
        const vaultsByProtocol = opportunityVaults.filter(
          (v) => v.top_protocol.toLowerCase() === p.toLowerCase()
        )
        if (vaultsByProtocol.length === 0) return
        const topOne = vaultsByProtocol.reduce((a, b) =>
          parseFloat(a.token.apy) > parseFloat(b.token.apy) ? a : b
        )
        _topVaults.push(topOne)
      })
      return _topVaults.sort((a, b) =>
        Number(a.token.apy) > Number(b.token.apy)
          ? -1
          : Number(b.token.apy) > Number(a.token.apy)
          ? 1
          : 0
      )
    }

    return _topVaults
  }, [memoizedProtocols, opportunityVaults])

  const openDepositTour = () => {
    dispatch(setOpenDepositPanel(true))
    dispatch(setTabPanelIndex(0))
    setTimeout(() => {
      setOverrideSteps(() => depositTabSteps(account))
      setIsJoyrideRunning(() => true)
    }, 250)
  }

  const isOnWrongChain = useMemo(() => chainId && chainId != 1, [chainId])

  if (!vaults) {
    return <PageLoading title={'Dashboard'}>Loading Dashboard...</PageLoading>
  }

  return (
    <div>
      <div className={'w-full container-sm mx-auto ease-app'}>
        {showTenderlyRpcAlert && (
          <TenderlyRpcAlert className={'mt-4 mb-4'}>
            When using the development version of Ease, please ensure you are
            using the most up-to-date Tenderly RPC:{' '}
            <span className={'font-bold'}>
              {globalConfig.simulation_fork && globalConfig.simulation_fork.rpc}
            </span>
          </TenderlyRpcAlert>
        )}

        {isOnWrongChain && (
          <WrongChainAlert
            chainId={chainId ? chainId : 0}
            className={'mb-4 mt-8'}
          />
        )}

        <PageTitle>
          <div className={'mt-4 flex gap-x-2 justify-center items-center'}>
            Dashboard{' '}
            <Tooltip
              // options
              title={'Click here to start a guided tour of the Ease app'}
              position="top"
              trigger="mouseenter"
            >
              <InformationCircleIcon
                onClick={() => setIsJoyrideRunning(true)}
                className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
              />
            </Tooltip>
          </div>
        </PageTitle>
        <Joyride
          steps={steps}
          run={isJoyrideRunning}
          stepIndex={stepIndex}
          scrollOffset={150}
          tooltipComponent={JoyrideTooltip}
          callback={handleJoyrideCallback}
          continuous={true}
        />

        {vaults && (
          <>
            {activeDepositVaults && (
              <div className={'active-deposits'}>
                <ActiveDeposits
                  vaults={activeDepositVaults}
                  withdrawalHours={withdrawalHours}
                  onUpdate={() => updateBalances()}
                />
              </div>
            )}
            {opportunityVaults && topVaults && (
              <Opportunities
                vaults={opportunityVaults}
                topVaults={topVaults}
                withdrawalHours={withdrawalHours}
                onUpdate={() => updateBalances()}
              />
            )}
          </>
        )}
      </div>
      <div className={'h-24'}>&nbsp;</div>
    </div>
  )
}
