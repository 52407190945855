import { Step } from 'react-joyride'
import React, { useMemo } from 'react'

export const infoTabSteps: Array<Step> = [
  {
    target: '.info-tab',
    placement: 'top',
    content: 'This tab shows information about the vault.',
    disableBeacon: true
  },
  {
    target: '.info-tab-protocol',
    placement: 'top',
    content: 'What type of protocol is it?',
    disableBeacon: true
  },
  {
    target: '.info-tab-underlying-protocols',
    placement: 'top',
    content:
      'Which underlying protocols are covered (all stacked risk is covered in Uninsurance!)',
    disableBeacon: true
  },
  {
    target: '.info-tab-instructions',
    placement: 'top',
    content:
      'Instructions on how to obtain the tokens, with a link to the pool where the tokens originate. Please read the instructions carefully as each protocol has its own instructions.',
    disableBeacon: true
  }
]
