import { Header } from './Header'
import React, { ReactNode, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useEagerConnect } from '../hooks/useEagerConnect'
import { useInactiveListener } from '../hooks/useInactiveListener'
import { toast, Toaster } from 'react-hot-toast'
import { CheckIcon, CogIcon, XIcon } from '@heroicons/react/solid'
import { AppDispatch } from '../store'
import { useAppDispatch } from '../hooks/redux'
import { fetchConfig, setIsDevelopment } from '../store/globalSlice'
import { Footer } from './Footer'
import { DevelopmentCog } from './DevelopmentCog'
import { isDevelopmentUrl } from '../classes/helpers'
import useGetVaults from '../hooks/api/useGetVaults'
import { setVaults } from '../store/vaultSlice'
import useGetFaucets from '../hooks/api/useGetFaucets'

export const Layout: React.FC<{
  children: React.PropsWithChildren<any>
}> = ({ children }) => {
  const { connector } = useWeb3React()
  const { isLoading, isError, data: vaults, error } = useGetVaults()
  const { data: faucets } = useGetFaucets()
  const dispatch = useAppDispatch()
  const [activatingConnector, setActivatingConnector] = React.useState<any>()

  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  const triedEager = useEagerConnect()
  useInactiveListener(!triedEager || !!activatingConnector)

  useEffect(() => {
    if (vaults) {
      dispatch(setVaults(vaults))
    }
  }, [vaults])

  useEffect(() => {
    dispatch(fetchConfig()).then((res) => {
      if (isDevelopmentUrl()) {
        dispatch(setIsDevelopment(true))
      }
    })
  }, [])

  return (
    <>
      <div className="App min-h-screen flex flex-col justify-between relative">
        <Header />
        <div className={'flex-grow'}>{children}</div>
        <Footer />
        <Toaster position="bottom-left" toastOptions={{ duration: 10000 }} />
      </div>
      {isDevelopmentUrl() && <DevelopmentCog faucets={faucets ? faucets : []} />}
    </>
  )
}

export const notifySuccess = (
  title: string,
  text: ReactNode,
  hash: string = ''
) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-2xl w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            <div className="flex items-center justify-center h-8 w-8 rounded-full bg-green-100">
              <CheckIcon className={'text-green-500 h-5 w-5'} />
            </div>
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-500">{text}</p>
            {hash != '' && (
              <p className="mt-1 text-xs text-blue-900 truncate">{hash}</p>
            )}
          </div>
        </div>
      </div>
      {hash != '' && (
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => {
              window.open(`https://etherscan.io/tx/${hash}`)
              toast.dismiss(t.id)
            }}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            View Receipt
          </button>
        </div>
      )}
    </div>
  ))
}

export const notifyPending = (title: string, text: ReactNode, hash: string) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-2xl w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            <div className="flex items-center justify-center h-8 w-8 rounded-full bg-blue-100">
              <CheckIcon className={'text-blue-500 h-5 w-5'} />
            </div>
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-500">{text}</p>
            <p className="mt-1 text-xs text-blue-900 truncate">{hash}</p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => {
            window.open(`https://etherscan.io/tx/${hash}`)
            toast.dismiss(t.id)
          }}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          View Receipt
        </button>
      </div>
    </div>
  ))
}

export const notifyError = (title: string, text: ReactNode) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-2xl w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            <div className="flex items-center justify-center h-8 w-8 rounded-full bg-red-100">
              <XIcon className={'text-red-500 h-5 w-5'} />
            </div>
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-500">{text}</p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Close
        </button>
      </div>
    </div>
  ))
}
