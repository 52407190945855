import { Tab } from '@headlessui/react'
import { classNames } from '../../classes/helpers'
import React from 'react'
import { Button } from '../Button'

export const FarmingTabPanel: React.FC<{
  children?: React.PropsWithChildren<any>
}> = ({ children }) => {
  const open = (url: string) => window.open(url)
  return (
    <Tab.Panel
      className={classNames(
        'text-white rounded-xl py-4 px-1 farming-tab',
        'focus:outline-none focus:ring-transparent'
      )}
    >
      <div
        className={'flex justify-center items-center gap-y-2 flex-col gap-x-3'}
      >
        <div className={'text-lg font-bold mb-2'}>Coming Soon</div>
        <div className={'mb-2'}>
          <Button
            onClick={() =>
              open(
                'https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/token-documentation-get-defi-cover-at-ease/farming-rationale/'
              )
            }
            size={'sm'}
          >
            More Information
          </Button>
        </div>
      </div>
    </Tab.Panel>
  )
}
