import { baseConfig } from './baseConfig'

const rinkebyConfig = {
  // GENERAL
  ...baseConfig,

  // CONTRACTS
  ARNFT_ADDRESS: '0x1337DEF1e9c7645352D93baf0b789D04562b4185',
  ARMOR_TOKEN_ADDRESS: '0xb2d0fBeD398a5fAd6b377CD204cE4D5C8B83Cdfe',
  VARMOR_ADDRESS: '0xBe71B865f37e0E5804dEcf367D46bd6A8619fa50',

  UNDERLYING_TOKEN_ADDRESS: '0x825541BD7E50B6cD9e68a9e509BA8537022BE075',
  CONTROLLER_ADDRESS: '0x41CaEdFc1856E5cA82Fe1DEC168F737FcFD390Ec',
  SHEILD_ADDRESS: '0x6bb5298d0F34cbb3B62F1a75E314B48AE00C7D7B',

  GOVERNANCE_ADDRESS: '0x0B6b2760A78b06526cFF73548B9E962cEFb193Fb',
  USER_GUARDIAN_ADDRESS: '0x0D272F4fE5D9F7bd0B5fC8d1044DD0EED97b4C6A',
  PRICE_ORACLE_ADDRESS: '0x9898923ae4D5D5F73517dB01335307d1c9580eAb',
  CAPACITY_ORACLE_ADDRESS: '0x47225cC9b1F93d984823a6f002437Bd16C2057A3',

  TOKEN_SWAP_ADDRESS: '0x276ad03c29e2834e926E74354FF050888CEA4725',
  EASE_TOKEN_ADDRESS: '0xd27d03A2230547c77a696C4DD8De7ec2F54421D0',
  GV_TOKEN_ADDRESS: '0xa764de035bCf754c0E87da4651e7D27b1d167d3F',
  BRIBE_POT_ADDRESS: '0xEA5EdeF17C9be57228389962ba50b98397f1E28C'
}

export default rinkebyConfig
