import { Link } from 'react-router-dom'
import React from 'react'
import clsx from 'clsx'

export const NavLink: React.FC<{
  children: React.PropsWithChildren<any>
  active?: boolean
  url: string
}> = ({ children, active = false, url }) => {
  return (
    <Link
      to={url}
      className={clsx(
        'text-blue-900 transition duration-300 ease-in-out hover:text-pink-500 transform hover:scale-105',
        active ? 'font-bold' : ''
      )}
    >
      {children}
    </Link>
  )
}
