import { VaultsApi } from '../hooks/api/useGetVaults'
import { formatUnits } from 'ethers/lib/utils'
import { BigNumber, Signer } from 'ethers'

export const sanitizeApys = (arr: VaultsApi.Vault[]) => {
  return arr.map((v) => {
    return v
  })
}
export type PermitSigArgs = {
  signer: Signer
  token: string
  spender: string
  value: BigNumber
  deadline: BigNumber
}
export const getDirValues = (dir: string) => {
  let first = 1
  let second = -1

  if (dir == 'desc') {
    first = -1
    second = 1
  }

  return [first, second]
}

export const sortByAvailableToDeposit = (
  arr: VaultsApi.Vault[],
  direction: string
) => {
  const [first, second] = getDirValues(direction)

  return arr.sort((a, b) =>
    formatTokenBalance(a) > formatTokenBalance(b)
      ? first
      : formatTokenBalance(b) > formatTokenBalance(a)
      ? second
      : 0
  )
}

export const sortByCapacity = (
  arr: VaultsApi.Vault[],
  direction: string
) => {
  const [first, second] = getDirValues(direction)

  return arr.sort((a, b) =>
    formatTokenBalance(a) > formatTokenBalance(b)
      ? first
      : formatTokenBalance(b) > formatTokenBalance(a)
      ? second
      : 0
  )
}

export const sortByApy = (arr: VaultsApi.Vault[], direction: string) => {
  const [first, second] = getDirValues(direction)

  return arr.sort((a, b) =>
    Number(a.token.apy) > Number(b.token.apy)
      ? first
      : Number(b.token.apy) > Number(a.token.apy)
      ? second
      : 0
  )
}

export const sortBySymbol = (arr: VaultsApi.Vault[], direction: string) => {
  const [first, second] = getDirValues(direction)

  return arr.sort((a, b) =>
    a.symbol.toLowerCase().replace('-', '') >
    b.symbol.toLowerCase().replace('-', '')
      ? first
      : b.symbol.toLowerCase().replace('-', '') >
        a.symbol.toLowerCase().replace('-', '')
      ? second
      : 0
  )
}

export const calcTotalAssets = (vault: VaultsApi.Vault) => {
  return BigNumber.from(vault.token.assets).mul(
    BigNumber.from((Number(vault.token.priceUSD) * 100).toFixed(0))
  )
}

export const sortByTotalAssets = (
  arr: VaultsApi.Vault[],
  direction: string
) => {
  const [first, second] = getDirValues(direction)

  return arr.sort((a, b) =>
    calcTotalAssets(a).gt(calcTotalAssets(b))
      ? first
      : calcTotalAssets(b).gt(calcTotalAssets(a))
      ? second
      : 0
  )
}

export const sortByPrice = (arr: VaultsApi.Vault[], direction: string) => {
  const [first, second] = getDirValues(direction)

  return arr.sort((a, b) =>
    a.token.price > b.token.price
      ? first
      : b.token.price > a.token.price
      ? second
      : 0
  )
}

export const formatTokenBalance = (vault: VaultsApi.Vault) => {
  if (vault?.token) {
    return Number(formatUnits(vault.balance, vault.token.decimals))
  }

  return 0
}

export const formatRcaBalance = (vault: VaultsApi.Vault) => {
  if (vault?.token) {
    return Number(formatUnits(vault.balance, vault.decimals))
  }
  return 0
}

export const calcValue = (vault: VaultsApi.Vault) => {
  return formatRcaBalance(vault) * vault.token.priceUSD
}

export const sortByValue = (arr: VaultsApi.Vault[], direction: string) => {
  const [first, second] = getDirValues(direction)

  return arr.sort((a, b) =>
    calcValue(a) > calcValue(b)
      ? first
      : calcValue(b) > calcValue(a)
      ? second
      : 0
  )
}

export const sortByMaxFee = (arr: VaultsApi.Vault[], direction: string) => {
  const [first, second] = getDirValues(direction)

  return arr.sort((a, b) =>
    Number(a.maxFee) > Number(b.maxFee)
      ? first
      : Number(b.maxFee) > Number(a.maxFee)
      ? second
      : 0
  )
}

export const sortByRcaBalance = (arr: VaultsApi.Vault[], direction: string) => {
  const [first, second] = getDirValues(direction)

  return arr.sort((a, b) =>
    formatRcaBalance(a) > formatRcaBalance(b)
      ? first
      : formatRcaBalance(b) > formatRcaBalance(a)
      ? second
      : 0
  )
}

export const sortByApyAndBalance = (
  arr: VaultsApi.Vault[],
  direction: string
) => {
  arr = sanitizeApys(arr)
  arr = sortByAvailableToDeposit(arr, 'desc')

  let _presort = arr.filter((s) => Number(s.balance) > 0)
  arr = arr.filter((s) => Number(s.balance) == 0)
  arr = sortByApy(arr, direction)

  return [..._presort, ...arr]
}

export const dedupeVaults = (vaults: VaultsApi.Vault[]) => {
  return vaults.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (v) => v.address === value.address && v.symbol === value.symbol
      )
  )
}
