import { Step } from 'react-joyride'

export const opportunitiesSteps: Array<Step> = [
  {
    target: '.opportunities-table',
    placement: 'top',
    content: 'This is the complete list of all current Uninsurance vaults.',
    disableBeacon: true
  },
  {
    target: '.opportunities-top-vaults',
    placement: 'top',
    content: 'These are currently the vaults with the highest apy.',
    disableBeacon: true
  },
  {
    target: '.opportunities-filters',
    placement: 'top',
    content:
      'You can filter them using the Protocol dropdown list or search by tokens or protocols in the search box',
    disableBeacon: true
  },
  {
    target: '.opportunities-sorting',
    placement: 'top',
    offset: 50,
    disableScrolling: true,
    disableScrollParentFix: true,
    content:
      'You can also sort the vault list Alphabetically, by APY, the total deposits per vault, or the amount of tokens you currently have that can be deposited.',
    disableBeacon: true
  }
]
