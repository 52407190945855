import { PageTitle } from './PageTitle'
import React from 'react'

export const PageLoading: React.FC<{
  children: React.PropsWithChildren<any>
  title: string
}> = ({ children, title }) => {
  return (
    <div>
      <div className={'w-full container-sm mx-auto'}>
        <PageTitle className={'mt-4'}>{title}</PageTitle>
        <div className={'mt-4 rounded-2xl bg-blackop-50 mx-2 md:mx-none mb-16'}>
          <div
            className={
              'p-4 text-white text-center text-lg flex flex-col items-center filter animate-pulse'
            }
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
