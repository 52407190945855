import {
  classNames,
  countDecimals,
  formatDecimals,
  makeWeb3Contract,
  weiToEth
} from '../../../classes/helpers'
import { Button } from '../../Button'
import React, {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { EaseStatsApi, EaseUsersApi } from '../../../hooks/api/useGetEase'
import { GetUserStatsResponse } from '../../../hooks/gvDashboard/useGetUserStats'
import { VaultsApi } from '../../../hooks/api/useGetVaults'
import useContractMethodSend from '../../../hooks/useContractMethodSend'
import Contracts from '../../../contracts'
import { useImmer } from 'use-immer'
import { useImpersonatableWeb3React } from '../../../hooks/useImpersonatableWeb3React'
import { LoadingModal } from '../../LoadingModal'
import { Modal } from '../../Modal'
import clsx from 'clsx'
import { CheckIcon, PlusCircleIcon, SelectorIcon } from '@heroicons/react/solid'
import { Listbox, Transition } from '@headlessui/react'
import { BigNumber } from 'ethers'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { formatEther, formatUnits, parseUnits } from 'ethers/lib/utils'
import moment from 'moment'
import { Tooltip } from 'react-tippy'

interface BribeData extends EaseUsersApi.Bribes {
  newBribe: boolean
  vault: VaultsApi.Vault
  isApi: boolean
  index: number
}

export const BribePanel: React.FC<{
  stats: GetUserStatsResponse
  vaults: VaultsApi.Vault[]
  userStats: EaseStatsApi.User
  easeStats: EaseStatsApi.Response
}> = ({ stats, vaults = [], userStats, easeStats }) => {
  const { account, library } = useImpersonatableWeb3React()
  const [modalValue, setModalValue] = useState<string>('50')
  const [isAddNewBribeModalOpen, setIsAddNewBribeModalOpen] =
    useState<boolean>(false)
  const [selected, setSelected] = useState(' Choose Vault')
  const [query, setQuery] = useState('')
  const [bribedVaults, setBribedVaults] = useImmer<BribeData[]>([])
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false)
  const [loadingModalTitle, setLoadingModalTitle] = useState<string>('')
  const [loadingModalBody, setLoadingModalBody] = useState<ReactNode>('')
  const [weeksOfBribes, setWeeksOfBribes] = useState<number>(1)
  const [bribeStartDate, setBribeStartDate] = useState<moment.Moment>()
  const indexRef = useRef(0)

  const calculateBribeStartDate = () => {
    const genesisStart = new Date(easeStats.genesis * 1000)
    return moment(genesisStart).add(easeStats.week + 1, 'weeks')
  }

  useEffect(() => {
    if(userStats){
      setBribeStartDate(calculateBribeStartDate())
      if (userStats.vaults) {
        let bribes: BribeData[] = []
        let index = 0
        for (let v of Object.keys(userStats.vaults)) {
          const vault = userStats.vaults[v as any]
          if (vault.bribes?.length > 0) {
            let vaultDetails = getVault(v)

            vault.bribes.forEach((bribe) => {
              bribes.push({
                ...bribe,
                newBribe: false,
                vault: vaultDetails as VaultsApi.Vault,
                isApi: true,
                index: index++,
              })
            })
          }
        }
        bribes.sort((a, b) => {
          if (a.status > b.status) return 1
          if (a.status < b.status) return -1
          return 0
        })
        setBribedVaults(bribes)
      }
    }
  }, [userStats, vaults])

  const getVault = (address: string) => {
    if (vaults) {
      let v = vaults.filter(
        (v) => v.address.toLowerCase() == address.toLowerCase()
      )
      if (v.length > 0) {
        return v[0]
      }
    }

    return null
  }
  const getVaultByDisplayName = (displayName: string): string => {
    if (vaults) {
      let v = vaults.filter(
        (v) => v?.display_name.toLowerCase() == displayName.toLowerCase()
      )
      if (v.length > 0) {
        return v[0].address
      }
    }

    return ''
  }

  const memoizedVaults = useMemo(() => {
    let _vaults = [' Choose Vault']
    if (vaults) {
      vaults.forEach((v) => {
        if (
          bribedVaults.find(
            (bVault) =>
              v?.display_name === bVault.vault?.display_name &&
              moment().isBefore(bVault.eventTime)
          )
        )
          return
        if (!_vaults.includes(v?.display_name)) {
          _vaults.push(v?.display_name)
        }
      })
    }
    return _vaults.sort(function (a, b) {
      return a.localeCompare(b, undefined, { sensitivity: 'base' })
    })
  }, [vaults, bribedVaults])

  const filteredVaults = useMemo(
    () =>
      query === ''
        ? memoizedVaults
        : memoizedVaults.filter((vault) =>
          vault
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        ),
    [memoizedVaults, query]
  )

  const onError = (err: Error) => {
    setLoadingModalOpen(false)
  }

  const onCancelBribeSuccess = () => {
    setLoadingModalOpen(false)
    setBribedVaults((v) => v.filter((b) => b.index != indexRef.current))

    Promise.all([
      stats.deposits.get([account]),
      stats.easeEarned.get([account]),
      stats.easeBalance.get([account])
    ])
      .catch((err) => console.error(err))
      .then((res) => {
        console.log('loaded')
      })
  }

  const onStopBribe = (address: string, index: number) => {
    setLoadingModalOpen(true)
    setLoadingModalTitle('Refund Bribe')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm the transaction that will stop and refund the remainder
          of your{' '}
          <span className={'text-pink-500 font-medium'}>
            {getVault(address)?.display_name}
          </span>
          bribe
        </div>
      </>
    )

    indexRef.current = index
    stopBribe([address]).then(() => { })
  }
  const onBribeSuccess = () => {
    setLoadingModalOpen(false)
    setBribedVaults((v) => {
      v.push({
        newBribe: true,
        bribePerWeek: parseUnits(modalValue.toString(), 18).toString(),
        contract: '',
        endWeek: weeksOfBribes,
        eventClass: '',
        eventName: 'BribeAdded',
        eventTime: new Date(),
        eventTimestamp: 0,
        index: v.length * 2,
        blockNumber: 0,
        key: '',
        logIndex: 0,
        receiver: '',
        sender: '',
        startWeek: 0,
        status: '',
        transactionFee: '',
        transactionHash: '',
        isApi: false,
        vault: getVault(getVaultByDisplayName(selected)) as VaultsApi.Vault
      })
      return v
    })
  }

  const bribe = useContractMethodSend({
    contract: Contracts.bribePot,
    methodName: 'bribe',
    onSuccess: onBribeSuccess,
    onError: onError,
    address: Contracts.bribePot.address
  })

  const stopBribe = useContractMethodSend({
    contract: Contracts.bribePot,
    methodName: 'cancelBribe',
    onSuccess: onCancelBribeSuccess,
    onError: onError,
    address: Contracts.bribePot.address
  })

  const onBribeApproval = async () => {
    setIsAddNewBribeModalOpen(false)
    const baseValue = Number(modalValue) * weeksOfBribes

    let amount = parseUnits(baseValue.toString(), 18)
    if (amount.gt(BigNumber.from(stats.easeBalance.value))) {
      amount = stats.easeBalance.value
    }

    const _contract = makeWeb3Contract(
      library.currentProvider,
      Contracts.easeToken,
      Contracts.easeToken.address
    )
    let result: BigNumber = await _contract.methods['allowance'](
      account,
      Contracts.bribePot.address
    ).call({
      from: account
    })
    if (BigNumber.from(result).gte(amount)) {
      onApproveSuccess()
      return
    }

    setLoadingModalOpen(true)
    setLoadingModalTitle('Approve $Ease for Bribe')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction to approve $Ease for bribing on{' '}
          <span className={'text-orange-500'}>{selected}</span>
          <div className={'text-gray-700 mt-2'}>
            {formatDecimals(Number(modalValue), 2, 2)} $Ease * {weeksOfBribes}{' '}
            Week
            {weeksOfBribes > 1 && <>s</>} = {formatDecimals(baseValue, 2, 2)}{' '}
            $Ease
          </div>
        </div>
      </>
    )

    await bribeApprove([
      Contracts.bribePot.address,
      parseUnits(baseValue.toString(), 18)
    ]).catch((err) => console.error(err))
  }

  const onApproveSuccess = () => {
    const baseValue = Number(modalValue) * weeksOfBribes

    let amount = parseUnits(baseValue.toString(), 18)
    if (amount.gt(BigNumber.from(stats.easeBalance.value))) {
      amount = stats.easeBalance.value
    }

    setLoadingModalOpen(true)
    setLoadingModalTitle('Bribe')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction to bribe on{' '}
          <span className={'text-orange-500'}>{selected}</span>
          <div className={'text-gray-700 mt-2'}>
            {formatDecimals(Number(modalValue), 2, 2)} $Ease * {weeksOfBribes}{' '}
            Week
            {weeksOfBribes > 1 && <>s</>} = {formatDecimals(baseValue, 2, 2)}{' '}
            $Ease
          </div>
        </div>
      </>
    )

    console.log(getVaultByDisplayName(selected))
    bribe([
      parseUnits(modalValue.toString(), 18),
      getVaultByDisplayName(selected),
      weeksOfBribes,
      [
        '0',
        0,
        '0x0000000000000000000000000000000000000000000000000000000000000000',
        '0x0000000000000000000000000000000000000000000000000000000000000000'
      ]
    ]).catch((err) => console.error(err))
  }

  const bribeApprove = useContractMethodSend({
    contract: Contracts.easeToken,
    methodName: 'approve',
    onSuccess: onApproveSuccess,
    onError: onError,
    address: Contracts.easeToken.address
  })

  const bribeTotal = (bribe: EaseUsersApi.Bribes) => {
    return (
      (Number(bribe.bribePerWeek) * (bribe.endWeek - bribe.startWeek)) /
      10 ** 18
    )
  }

  const calculateMaxFee = (bribeAmount: number, vaultDisplayName: string, estimateEffect: boolean) => {
    if(vaultDisplayName == ' Choose Vault') return 0
    bribeAmount = estimateEffect ? bribeAmount : 0

    const currVaultDetails = vaults.find(
      (v) => v?.display_name === vaultDisplayName
    )

    let currVaultAddr: string
    if (currVaultDetails) {
      currVaultAddr = currVaultDetails.address
    } else {
      return 33
    }
    let maxFee: number = 33
    if (easeStats?.rcaAmountEth && currVaultAddr) {
      const totalEth = Number(formatEther(easeStats?.rcaAmountEth || 0))
      const totalStaked = Number(
        formatUnits(BigNumber.from(easeStats?.gvEaseStaked || 0), 18)
      )
      const currVault = easeStats?.vaults[currVaultAddr]
      const vaultEth = Number(formatEther(currVault?.rcaAmountEth || 0))
      const weeklyBribe =
        currVault?.weeklyBribes && currVault?.weeklyBribes[easeStats.week + 1]
          ? currVault?.weeklyBribes[easeStats.week + 1]
          : ({} as EaseStatsApi.WeeklyBribe)

      const vaultStaked = Number(formatEther(currVault?.gvEaseStaked || 0))

      const bribesOnVault = Number(formatEther(weeklyBribe.ease || 0))

      const totalBribes = Number(
        formatEther(
          easeStats?.weeklyBribes && easeStats?.weeklyBribes[easeStats.week + 1]
            ? easeStats?.weeklyBribes[easeStats.week + 1].ease || 0
            : 0
        )
      )
      const leasedAmount = Number(formatEther(easeStats?.leasedAmount || 0))

      const newBribesOnVault = bribesOnVault + bribeAmount

      const newGvEaseBribed =
        (newBribesOnVault / (totalBribes + bribeAmount)) * leasedAmount
      const newGvEasePower = newGvEaseBribed + vaultStaked

      const totalPower = totalStaked + leasedAmount
      if (vaultEth > 0 && totalPower > 0) {
        // this check is to avoid divide by zero
        maxFee = ( 0.33 - 0.13 * (newGvEasePower / totalPower) * (totalEth / vaultEth)) * 100
      }
    }

    return maxFee > 0 ? maxFee : 0
  }

  const isBribed = (vault: string) => {
    let result = false
    bribedVaults.map((bribe, index) => {
      if (
        bribe.vault?.display_name === vault &&
        moment(bribe.eventTime)
          .utc()
          .add(bribe.endWeek - bribe.startWeek, 'weeks')
          .diff(moment()) >= 0
      )
        result = true
    })
    return result
  }

  const activeBribes = () => {
    const result = bribedVaults.filter(
      (bribe) =>
        moment(bribe.eventTime)
          .utc()
          .add(bribe.endWeek - bribe.startWeek, 'weeks')
          .diff(moment()) >= 0
    )
    return result
  }
  console.log(
    bribedVaults.filter(
      (bribe) =>
        moment(bribe.eventTime)
          .utc()
          .add(bribe.endWeek - bribe.startWeek, 'weeks')
          .diff(moment()) >= 0
    )
  )

  return (
    <>
      <div className={'grid grid-cols-9 pl-1 items-center'}>
        <div className={'text-white flex font-bold col-span-2'}>Vault</div>
        <div className={'text-white flex justify-end font-bold'}>
          Bribe/Week ($Ease)
          <Tooltip
            className="ml-1"
            title="This is the current bribe amount per week. - You can stop the bribe at any moment by clicking the [Refund]  button and signing the transaction, after which you will receive the remaining $Ease you paid as bribes. - If you want to lengthen the bribe period and/or increase the weekly bribe amount, then you should [Refund] first and then start a new bribe for that same vault. You cannot add a 2nd bribe for a vault that you already have an active bribe for."
            position="top"
            trigger="mouseenter"
          >
            <InformationCircleIcon
              onClick={() => { }}
              className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
            />
          </Tooltip>
        </div>
        <div className={'text-white flex justify-end font-bold col-span-2'}>
          Power ($gvEase)
          <Tooltip
            className="ml-1"
            title="The amount of $gvEase Power you receive in return for your bribe."
            position="top"
            trigger="mouseenter"
          >
            <InformationCircleIcon
              onClick={() => { }}
              className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
            />
          </Tooltip>
        </div>
        <div className={'text-white flex justify-end font-bold'}>
          Max Fee
          <Tooltip
            className="ml-1"
            title="The vault's predicted maximum fee once this bribe is active."
            position="top"
            trigger="mouseenter"
          >
            <InformationCircleIcon
              onClick={() => { }}
              className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
            />
          </Tooltip>
        </div>
        <div className={'text-white flex justify-end font-bold text-right'}>
          Start Date
        </div>
        <div className={'text-white flex justify-end font-bold text-right'}>
          End Date
          <Tooltip
            className="ml-1"
            title={
              'This is the current end date of your bribe. - The bribe will stop automatically at this date. - You can stop the bribe at any moment by clicking the [Refund]  button and signing the transaction, after which you will receive the remaining $Ease you paid as bribes. - If you want to lengthen the bribe period and/or increase the weekly bribe amount, then you should [Refund] first and then start a new bribe for that same vault. You cannot add a 2nd bribe for a vault that you already have an active bribe for.'
            }
            position="top"
            trigger="mouseenter"
          >
            <InformationCircleIcon
              onClick={() => { }}
              className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
            />
          </Tooltip>
        </div>
        <div>&nbsp;</div>
      </div>

      {(!bribedVaults || bribedVaults.length == 0) && (
        <div
          className={
            'flex gap-x-4 justify-center text-center text-white bg-blackop-20 rounded-lg p-4 mt-2'
          }
        >
          <InformationCircleIcon className={'h-6 w-6'} />
          <a
            href="https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/product-guides/how-to-bribe-for-gvease-for-protocols/"
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            Bribes allow you to pay a weekly amount of $Ease to have users stake
            their $gvEase on your chosen vault. They cannot be used for
            purchasing governance power. Read more here.
          </a>
        </div>
      )}

      {bribedVaults &&
        bribedVaults.length > 0 &&
        bribedVaults.filter(
            (bribe) =>
              moment(bribe.eventTime)
                .utc()
                .add(bribe.endWeek - bribe.startWeek, 'weeks')
                .diff(moment()) >= 0
          )
          .map((bribe, idx) => (
            <div
              key={`${bribe.vault?.address}${bribe.index}${idx}`}
              className={
                'grid grid-cols-9 mt-2 bg-blackop-30 p-3 rounded-lg text-white'
              }
            >
              <div
                className={
                  'flex gap-x-1 items-center overflow-hidden col-span-2'
                }
              >
                <img
                  src={bribe.vault?.icon}
                  className={'w-4 h-4'}
                  alt={'convex'}
                />
                <div>{bribe.vault?.display_name}</div>
              </div>
              <div className={'font-bold text-right'}>
                {Math.round(Number(bribe.bribePerWeek) / 10 ** 18)}
              </div>
              <div className={'font-bold text-right col-span-2'}>
                {formatDecimals(
                  (Number(
                    formatUnits(BigNumber.from(bribe.bribePerWeek || 1), 18)
                  ) *
                    Number(
                      formatUnits(BigNumber.from(easeStats.leasedAmount), 18)
                    )) /
                  Number(
                    formatUnits(
                      BigNumber.from(
                        easeStats?.weeklyBribes[easeStats.week + 1].ease || 1
                      ),
                      18
                    )
                  ),
                  4,
                  2
                )}{' '}
              </div>
              <div className={'font-bold text-right'}>
                {`${formatDecimals(
                  calculateMaxFee( Math.round(Number(bribe.bribePerWeek) / 10 ** 18), bribe.vault?.display_name , bribe.newBribe ),
                  0,
                  0
                )}%`}
              </div>
              <div className={'text-right'}>
                {moment(bribeStartDate).utc().format('YYYY-MM-DD')}
              </div>
              <div className={'text-right'}>
                {bribe.eventTime &&
                  moment(bribeStartDate)
                    .utc()
                    .add(bribe.endWeek - bribe.startWeek, 'weeks')
                    .format('YYYY-MM-DD')}
              </div>
              <div className={'flex justify-end gap-x-2'}>
                {bribe.eventName != 'BribeCanceled' && (
                  <Button
                    onClick={() =>
                      onStopBribe(bribe.vault?.address, bribe.index)
                    }
                    variant={'orange'}
                    size={'xs'}
                  >
                    Refund
                  </Button>
                )}
                {bribe.eventName == 'BribeCanceled' && (
                  <div className={'font-medium text-red-500'}>Canceled</div>
                )}
              </div>
            </div>
          ))}

      <div
        className={
          'bg-blackop-50 p-3 mt-2 rounded-lg text-white flex justify-center gap-x-2 items-center cursor-pointer hover:bg-blackop-60'
        }
        onClick={() => {
          setModalValue('50')
          setSelected(' Choose Vault')
          setIsAddNewBribeModalOpen(true)
        }}
      >
        <PlusCircleIcon className={'h-4 w-4 text-white'} /> Add New Bribe
      </div>

      <LoadingModal
        open={loadingModalOpen}
        title={loadingModalTitle}
        onClose={() => { }}
      >
        {loadingModalBody}
      </LoadingModal>

      <Modal
        open={isAddNewBribeModalOpen}
        title={'Add New Bribe'}
        onClose={() => setIsAddNewBribeModalOpen(false)}
      >
        <div className={'flex flex-col justify-center mt-4'}>
          <div className={'mt-4 mb-4'}>
            Estimated Max Fee applies to the next bribe period, starting:
            <strong > {moment(bribeStartDate).utc().format('LLLL')} </strong>
            and lasting 7 days.
          </div>
          <div className={'gap-x-2 items-center w-full'}>
            <label className={'font-bold'}>Vault:</label>
            <Listbox value={selected} onChange={setSelected}>
              {({ open }) => (
                <>
                  <div className="relative">
                    <Listbox.Button className="relative w-full px-4 py-2 pl-3 pr-10 text-base text-left text-black bg-white rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500 sm:text-base">
                      <span className="block truncate">{selected}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredVaults.map((vault) => (
                          <Listbox.Option
                            key={vault}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'text-white bg-orange-500'
                                  : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-9'
                              )
                            }
                            value={vault}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate',
                                    isBribed(vault) ? 'text-gray-300' : ''
                                  )}
                                >
                                  {vault}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-orange-500',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          <div className={'gap-x-2 items-center w-full mt-2 grid grid-cols-3'}>
            <div>
              <label className={'font-bold'}>$Ease per week:</label>
              <input
                type="text"
                name="price"
                id="price"
                disabled={false}
                value={modalValue}
                pattern="[0-9\.]*"
                autoComplete={'off'}
                onChange={(e) => {
                  setModalValue((v) => {
                    if (countDecimals(modalValue) > 10) {
                      return parseFloat(e.target.value).toFixed(10)
                    }
                    return e.target.validity.valid ? e.target.value : v
                  })
                }}
                className={clsx(
                  'font-medium text-black shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'
                )}
                placeholder="0"
              />
            </div>
            <div className={''}>
              <label className={'font-bold'}>Weeks:</label>
              <select
                value={weeksOfBribes}
                onChange={(e) => setWeeksOfBribes(Number(e.target.value))}
                className={clsx(
                  modalValue == '0' ? 'bg-gray-200 text-gray-500' : '',
                  'font-medium text-black shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'
                )}
              >
                <option value={1}>1 Week</option>
                <option value={2}>2 Weeks</option>
                <option value={3}>3 Weeks</option>
                <option value={4}>4 Weeks</option>
                <option value={12}>12 Weeks</option>
                <option value={24}>24 Weeks</option>
                <option value={52}>52 Weeks</option>
              </select>
            </div>
            <div>
              <label className={'font-bold'}>Est. Max Fee Once Active:</label>
              <input
                type="text"
                name="price"
                id="price"
                disabled={true}
                value={`${formatDecimals(
                  calculateMaxFee(Number(modalValue), selected, true),
                  0,
                  0
                )}%`}
                autoComplete={'off'}
                className={clsx(
                  'px-0 font-medium border-transparent text-black focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'
                )}
                placeholder="0x"
              />
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button
            onClick={() => onBribeApproval()}
            disabled={
              weiToEth(stats.easeBalance.value) < parseFloat(modalValue) ||
              Number(modalValue) == 0 ||
              selected == ' Choose Vault' ||
              !account ||
              isBribed(selected)
            }
            classes={'sm:col-start-2 capitalize font-normal'}
          >
            Bribe
          </Button>
          <Button
            onClick={() => setIsAddNewBribeModalOpen(false)}
            variant={'white'}
            classes={'capitalize font-normal'}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  )
}
