import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../components/PageTitle'
import { PageLoading } from '../components/PageLoading'
import { ProofDetail, proofDetails } from '../config/proofDetails'
import { Tooltip } from 'react-tippy'
import { WrongChainAlert } from '../components/WrongChainAlert'
import { useGetUserStats } from '../hooks/gvDashboard/useGetUserStats'
import { Button } from '../components/Button'
import { LoadingModal } from '../components/LoadingModal'
import useContractMethodSend from '../hooks/useContractMethodSend'
import Contracts from '../contracts'
import { ConnectWalletPage } from '../components/ConnectWalletPage'
import { ArrowCircleDownIcon } from '@heroicons/react/solid'
import {
  classNames,
  countDecimals,
  formatDecimals,
  makeWeb3Contract,
  formatDelimiter
} from '../classes/helpers'
import { LoadingModalSteps, ModalStep } from '../components/LoadingModalSteps'
import {
  formatEther,
  formatUnits,
  parseEther,
  parseUnits
} from 'ethers/lib/utils'
import { BigNumber } from 'ethers'
import { UpDownArrowsIcon } from '../components/icons/UpDownArrowsIcon'
import clsx from 'clsx'
import { useImpersonatableWeb3React } from '../hooks/useImpersonatableWeb3React'
import { InformationAlert } from '../components/InformationAlert'
import { InfoAlert } from '../components/InfoAlert'
import { Link } from 'react-router-dom'

enum Steps {
  APPROVE = 1,
  APPROVE_EASE = 2,
  SWAP_TOKENS = 3
}

type ArmorTokens = '$vArmor' | '$Armor'
type EaseTokens = '$EASE' | '$gvEASE'

export const TokenSwap: React.FC<{}> = () => {
  const { account, chainId, library } = useImpersonatableWeb3React()
  const [isLoading, setIsLoading] = useState(false)
  const [swapValue, setSwapValue] = useState('0.00')
  const [currentStep, setCurrentStep] = useState(Steps.APPROVE)
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false)
  const [showSuccessInfo, setShowSuccessInfo] = useState<boolean>(false)
  const [loadingModalTitle, setLoadingModalTitle] = useState<string>('')
  const [selectedArmorToken, setSelectedArmorToken] =
    useState<ArmorTokens>('$Armor')
  const [selectedEaseToken, setSelectedEaseToken] =
    useState<EaseTokens>('$EASE')

  const [userProofDetails, setUserProofDetails] = useState<
    ProofDetail | undefined
  >()

  const infiniteApproval = BigNumber.from('1000000000000000000000000000')
  const pageTitle = '$EASE Token Swap'
  const stats = useGetUserStats()
  const toggleSelectedArmorToken = () => {
    if (selectedArmorToken == '$vArmor') {
      setSelectedArmorToken('$Armor')
      setSwapValue(formatDecimals(parseFloat(sanitizeSwapValue(stats.armorBalance.value)), 2, 0))
    } else {
      setSelectedArmorToken('$vArmor')
      setSelectedEaseToken('$gvEASE')
      setSwapValue(formatDecimals(parseFloat(sanitizeSwapValue(stats.vArmorBalance.value)), 2, 0))
    }
  }

  const toggleSelectedEaseToken = () => {
    if (selectedEaseToken == '$gvEASE') {
      setSelectedEaseToken('$EASE')
    } else {
      setSelectedEaseToken('$gvEASE')
    }
  }

  const sanitizeSwapValue = (value: string) => {
    let swapTo = formatUnits(value, 18)
    if (countDecimals(swapTo) > 10) {
      swapTo = parseFloat(swapTo).toFixed(10)
    }
    return swapTo
  }

  const isOnWrongChain = useMemo(() => chainId && chainId != 1, [chainId])

  const showPowerWarning = useMemo(() => {
    const actualVarmorBalance = BigNumber.from(
      stats.vArmorBalance ? stats.vArmorBalance.value : 0
    )
    const expectedBalance = BigNumber.from(
      userProofDetails ? userProofDetails.vArmorAmount : 0
    )
    if (actualVarmorBalance.lt(expectedBalance)) {
      if (
        selectedEaseToken == '$EASE' ||
        (selectedArmorToken == '$Armor' && selectedEaseToken == '$gvEASE')
      ) {
        return false
      }
      return true
    }
    return (
      !userProofDetails &&
      selectedArmorToken == '$vArmor' &&
      selectedEaseToken == '$gvEASE'
    )
  }, [
    userProofDetails,
    selectedArmorToken,
    selectedEaseToken,
    stats.vArmorBalance
  ])
  // smart contract callbacks and event handlers
  const onError = (err: Error) => {
    setLoadingModalOpen(false)
  }

  const onSwapSuccess = () => {
    setLoadingModalOpen(false)
    Promise.all([
      stats.vArmorBalance.get([account]),
      stats.armorBalance.get([account]),
      stats.vArmorAllowance.get([account, Contracts.tokenSwap.address]),
      stats.armorAllowance.get([account, Contracts.tokenSwap.address]),
      stats.gvTokenBalanceOf.get([account]),
      stats.easeBalance.get([account]),
      stats.easeAllowance.get([account, Contracts.gvToken.address])
    ])
      .catch((err) => console.error(err))
      .then((res) => {
        //console.log('loaded')
      })
    setShowSuccessInfo(true)
  }

  const onApproveSuccess = async () => {
    if (selectedEaseToken == '$gvEASE' && currentStep == Steps.APPROVE) {
      setCurrentStep(Steps.APPROVE_EASE)
    } else {
      setCurrentStep(Steps.SWAP_TOKENS)
    }
    Promise.all([
      stats.vArmorAllowance.get([account, Contracts.tokenSwap.address]),
      stats.armorAllowance.get([account, Contracts.tokenSwap.address]),
      stats.easeAllowance.get([account, Contracts.gvToken.address])
    ])
      .catch((err) => console.error(err))
      .then((res) => {})

    let wei = parseUnits(formatDelimiter(swapValue), 18)
    if (selectedArmorToken == '$vArmor') {
      if (wei.gt(stats.vArmorBalance.value)) {
        wei = stats.vArmorBalance.value
      }
      if (selectedEaseToken == '$EASE') {
        swapVArmor([wei]).catch((err) => console.error(err))
      } else {
        // this if is just to please typescript
        // if we are inside here proof details will always be here
        if (proofDetails) {
          wei = BigNumber.from(userProofDetails?.easeAmount || 0)
        }
        const easeTokenContract = makeWeb3Contract(
          library.currentProvider,
          Contracts.easeToken,
          Contracts.easeToken.address
        )
        let easeAllowance: BigNumber = await easeTokenContract.methods[
          'allowance'
        ](account, Contracts.gvToken.address).call({
          from: account
        })

        if (BigNumber.from(wei).gt(easeAllowance)) {
          await approveEase([
            Contracts.gvToken.address,
            BigNumber.from(wei)
          ]).catch((err) => console.error(err))
        }
        if (userProofDetails) {
          depositWithVArmor([
            BigNumber.from(userProofDetails.easeAmount),
            BigNumber.from(userProofDetails.vArmorAmount),
            userProofDetails.depositStart,
            userProofDetails.proof,
            [
              '0',
              0,
              '0x0000000000000000000000000000000000000000000000000000000000000000',
              '0x0000000000000000000000000000000000000000000000000000000000000000'
            ]
          ]).catch((err) => console.error(err))
        }
      }
    } else {
      if (wei.gt(stats.armorBalance.value)) {
        wei = stats.armorBalance.value
      }

      if (selectedEaseToken == '$EASE') {
        swap([wei]).catch((err) => console.error(err))
      } else {
        const easeTokenContract = makeWeb3Contract(
          library.currentProvider,
          Contracts.easeToken,
          Contracts.easeToken.address
        )
        let easeAllowance: BigNumber = await easeTokenContract.methods[
          'allowance'
        ](account, Contracts.gvToken.address).call({
          from: account
        })

        if (BigNumber.from(wei).gt(easeAllowance)) {
          await approveEase([
            Contracts.gvToken.address,
            BigNumber.from(wei)
          ]).catch((err) => console.error(err))
        }

        depositWithArmor([
          wei,
          [
            '0',
            0,
            '0x0000000000000000000000000000000000000000000000000000000000000000',
            '0x0000000000000000000000000000000000000000000000000000000000000000'
          ]
        ]).catch((err) => console.error(err))
      }
    }
  }

  const onApproveEaseSuccess = () => {
    setCurrentStep(Steps.SWAP_TOKENS)
    Promise.all([
      stats.vArmorAllowance.get([account, Contracts.tokenSwap.address]),
      stats.armorAllowance.get([account, Contracts.tokenSwap.address]),
      stats.easeAllowance.get([account, Contracts.easeToken.address])
    ])
      .catch((err) => console.error(err))
      .then((res) => {})
  }

  const approveEase = useContractMethodSend({
    contract: Contracts.easeToken,
    methodName: 'approve',
    onSuccess: onApproveEaseSuccess,
    onError: onError,
    address: Contracts.easeToken.address
  })

  const approveArmor = useContractMethodSend({
    contract: Contracts.armorToken,
    methodName: 'approve',
    onSuccess: onApproveSuccess,
    onError: onError,
    address: Contracts.armorToken.address
  })
  // 70005

  const approveVArmor = useContractMethodSend({
    contract: Contracts.vArmorToken,
    methodName: 'approve',
    onSuccess: onApproveSuccess,
    onError: onError,
    address: Contracts.vArmorToken.address
  })

  const depositWithArmor = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'depositWithArmor',
    onSuccess: onSwapSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const depositWithVArmor = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'depositWithVArmor',
    onSuccess: onSwapSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const swap = useContractMethodSend({
    contract: Contracts.tokenSwap,
    methodName: 'swap',
    onSuccess: onSwapSuccess,
    onError: onError,
    address: Contracts.tokenSwap.address
  })

  const swapVArmor = useContractMethodSend({
    contract: Contracts.tokenSwap,
    methodName: 'swapVArmor',
    onSuccess: onSwapSuccess,
    onError: onError,
    address: Contracts.tokenSwap.address
  })

  const onSwapGvEase = async () => {
    setLoadingModalTitle('Swap Tokens')
    setLoadingModalOpen(true)

    let wei = parseUnits(formatDelimiter(swapValue), 18)
    if (selectedArmorToken == '$vArmor') {
      // check if vArmor is approved
      if (wei.gt(stats.vArmorBalance.value)) {
        wei = stats.vArmorBalance.value
      }

      // swap if we have enough allowance
      const _contract = makeWeb3Contract(
        library.currentProvider,
        Contracts.vArmorToken,
        Contracts.vArmorToken.address
      )
      let result: BigNumber = await _contract.methods['allowance'](
        account,
        Contracts.tokenSwap.address
      ).call({
        from: account
      })

      if (BigNumber.from(wei).lte(result)) {
        setCurrentStep(Steps.SWAP_TOKENS)

        if (!userProofDetails) {
          return
        }

        const _contract = makeWeb3Contract(
          library.currentProvider,
          Contracts.easeToken,
          Contracts.easeToken.address
        )
        let result: BigNumber = await _contract.methods['allowance'](
          account,
          Contracts.gvToken.address
        ).call({
          from: account
        })

        if (BigNumber.from(userProofDetails.easeAmount).gt(result)) {
          await approveEase([
            Contracts.gvToken.address,
            BigNumber.from(userProofDetails.easeAmount)
          ]).catch((err) => console.error(err))
        }

        if (BigNumber.from(wei).lte(result)) {
          depositWithVArmor([
            BigNumber.from(userProofDetails.easeAmount),
            BigNumber.from(userProofDetails.vArmorAmount),
            userProofDetails.depositStart,
            userProofDetails.proof,
            [
              '0',
              0,
              '0x0000000000000000000000000000000000000000000000000000000000000000',
              '0x0000000000000000000000000000000000000000000000000000000000000000'
            ]
          ]).catch((err) => console.error(err))
        }

        return
      }

      approveVArmor([Contracts.tokenSwap.address, infiniteApproval]).catch(
        (err) => console.error(err)
      )
    } else {
      if (wei.gt(stats.armorBalance.value)) {
        wei = stats.armorBalance.value
      }

      const _contract = makeWeb3Contract(
        library.currentProvider,
        Contracts.armorToken,
        Contracts.armorToken.address
      )
      let result: BigNumber = await _contract.methods['allowance'](
        account,
        Contracts.gvToken.address
      ).call({
        from: account
      })

      // swap if we have enough allowance
      if (BigNumber.from(wei).lte(result)) {
        setCurrentStep(Steps.APPROVE_EASE)
        const easeTokenContract = makeWeb3Contract(
          library.currentProvider,
          Contracts.easeToken,
          Contracts.easeToken.address
        )
        let easeAllowance: BigNumber = await easeTokenContract.methods[
          'allowance'
        ](account, Contracts.gvToken.address).call({
          from: account
        })

        if (BigNumber.from(wei).gt(easeAllowance)) {
          await approveEase([
            Contracts.gvToken.address,
            BigNumber.from(wei)
          ]).catch((err) => console.error(err))
        }

        setCurrentStep(Steps.SWAP_TOKENS)
        console.log('deposit with armor', wei)
        depositWithArmor([
          wei,
          [
            '0',
            0,
            '0x0000000000000000000000000000000000000000000000000000000000000000',
            '0x0000000000000000000000000000000000000000000000000000000000000000'
          ]
        ]).catch((err) => console.error(err))

        return
      }

      setCurrentStep(Steps.APPROVE)
      approveArmor([Contracts.tokenSwap.address, wei]).catch((err) =>
        console.error(err)
      )
    }
  }

  const onSwapEase = async () => {
    setLoadingModalTitle('Swap Tokens')
    setLoadingModalOpen(true)

    let wei = parseUnits(formatDelimiter(swapValue), 18)
    if (selectedArmorToken == '$vArmor') {
      if (wei.gt(stats.vArmorBalance.value)) {
        wei = stats.vArmorBalance.value
      }

      // swap if we have enough allowance
      const _contract = makeWeb3Contract(
        library.currentProvider,
        Contracts.vArmorToken,
        Contracts.vArmorToken.address
      )
      let result: BigNumber = await _contract.methods['allowance'](
        account,
        Contracts.tokenSwap.address
      ).call({
        from: account
      })

      if (BigNumber.from(wei).lte(result)) {
        setCurrentStep(Steps.SWAP_TOKENS)
        swapVArmor([wei]).catch((err) => console.error(err))
        return
      }

      setCurrentStep(Steps.APPROVE)
      approveVArmor([Contracts.tokenSwap.address, infiniteApproval]).catch(
        (err) => console.error(err)
      )
    } else {
      if (wei.gt(stats.armorBalance.value)) {
        wei = stats.armorBalance.value
      }

      const _contract = makeWeb3Contract(
        library.currentProvider,
        Contracts.armorToken,
        Contracts.armorToken.address
      )
      let result: BigNumber = await _contract.methods['allowance'](
        account,
        Contracts.tokenSwap.address
      ).call({
        from: account
      })

      // swap if we have enough allowance
      if (BigNumber.from(wei).lte(result)) {
        setCurrentStep(Steps.SWAP_TOKENS)
        swap([wei]).catch((err) => console.error(err))
        return
      }

      setCurrentStep(Steps.APPROVE)
      approveArmor([Contracts.tokenSwap.address, wei]).catch((err) =>
        console.error(err)
      )
    }
  }

  useEffect(() => {
    if (account && chainId == 1) {
      setIsLoading(true)
      Promise.all([
        stats.vArmorBalance.get([account]),
        stats.armorBalance.get([account]),
        stats.vArmorAllowance.get([account, Contracts.tokenSwap.address]),
        stats.armorAllowance.get([account, Contracts.tokenSwap.address]),
        stats.gvTokenBalanceOf.get([account]),
        stats.easeBalance.get([account]),
        stats.easeAllowance.get([account, Contracts.gvToken.address])
      ])
        .catch((err) => {
          console.error(err)
          setIsLoading(false)
        })
        .then((res) => {
          setUserProofDetails(
            proofDetails.find(
              (pd) => pd.address.toLowerCase() == account.toLowerCase()
            )
          )
          if (selectedArmorToken == '$vArmor') {
            if (Number(stats.vArmorBalance.value) > 0) {
              setSwapValue(formatDecimals(parseFloat(sanitizeSwapValue(stats.vArmorBalance.value)), 2, 0))
            }
          } else {
            if (Number(stats.armorBalance.value) > 0) {
              setSwapValue(formatDecimals(parseFloat(sanitizeSwapValue(stats.armorBalance.value)), 2, 0))
            }
          }
          setIsLoading(false)
        })
    }
  }, [account, chainId])

  const setMax = () => {
    let swapTo = formatUnits(
      selectedArmorToken == '$vArmor'
        ? stats.vArmorBalance.value
        : stats.armorBalance.value,
      18
    )
    if (countDecimals(swapTo) > 10) {
      swapTo = parseFloat(swapTo).toFixed(10)
    }

    setSwapValue(formatDecimals(parseFloat(swapTo), 2, 0))
  }

  const currentBalance = () => {
    return selectedArmorToken == '$Armor'
      ? stats.armorBalance.value
      : stats.vArmorBalance.value
  }

  const receiveAmount = () => {
    if (selectedArmorToken == '$vArmor') {
      let valueInWei = getSwapValueInEase()
      if (selectedEaseToken == '$gvEASE' && userProofDetails) {
        const growthPeriod = 52 * 7 * 24 * 60 * 60
        const currentTime = Math.round(new Date().getTime() / 1000)
        valueInWei = valueInWei.add(
          valueInWei
            .mul(currentTime - +userProofDetails?.depositStart)
            .div(growthPeriod)
        )
      }

      const finalSwapValue = formatEther(valueInWei)

      return Number(finalSwapValue)
    }
    return Number(formatDelimiter(swapValue))
  }
  const getSwapValueInEase = () => {
    let valueInWei = parseEther(formatDelimiter(swapValue))
    // vArmor to armor exchange rate
    const exchangeRate = 117686
    // buffer for vArmor
    const buffer = 100000

    valueInWei = valueInWei.mul(exchangeRate).div(buffer)
    return valueInWei
  }

  const bonusAmount = () => {
    if (selectedEaseToken == '$gvEASE' && selectedArmorToken == '$vArmor') {
      return receiveAmount() - Number(formatEther(getSwapValueInEase()))
    }
    return 0
  }

  useEffect(() => {
    if (
      Number(stats.vArmorBalance.value) > 0 &&
      Number(formatDelimiter(swapValue)) == 0 &&
      selectedArmorToken == '$vArmor'
    ) {
      setSwapValue(formatDecimals(parseFloat(sanitizeSwapValue(stats.vArmorBalance.value)), 2, 0))
    }
  }, [stats.vArmorBalance.value])

  useEffect(() => {
    if (
      Number(stats.armorBalance.value) > 0 &&
      Number(formatDelimiter(swapValue)) == 0 &&
      selectedArmorToken == '$vArmor'
    ) {
      setSwapValue(formatDecimals(parseFloat(sanitizeSwapValue(stats.armorBalance.value)), 2, 0))
    }
  }, [stats.armorBalance.value])

  const onSwapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSwapValue((v) => {
      try {
        let maxVal =
          selectedArmorToken == '$Armor'
            ? stats.armorBalance.value
            : stats.vArmorBalance.value
        
        if (parseUnits(formatDelimiter(e.target.value), 18).gt(maxVal)) {
          let mv = formatUnits(maxVal, 18)
          return formatDecimals(parseFloat(mv), 2, 0)
        }
      } catch {}
      return e.target.value ? formatDecimals(parseFloat(formatDelimiter(e.target.value)), 2, 0) : '0'
    })
  }
  const stepsGvEase = useMemo(
    (): ModalStep[] => [
      {
        name: `Approve ${selectedArmorToken} Transfer`,
        description: `Approve ${formatDecimals(
          Number(formatDelimiter(swapValue)),
          0,
          0
        )} ${selectedArmorToken} tokens to be swapped.`,
        status: currentStep == Steps.APPROVE ? 'current' : 'complete'
      },
      {
        name: 'Approve Ease Transfer',
        description: `Approve ${formatDecimals(
          Number(formatDelimiter(swapValue)),
          0,
          0
        )} Ease tokens to be swapped.`,
        status:
          currentStep == Steps.APPROVE_EASE
            ? 'current'
            : currentStep < Steps.APPROVE_EASE
            ? 'upcoming'
            : 'complete'
      },
      {
        name: 'Swap',
        description: `Swap ${formatDecimals(
          Number(formatDelimiter(swapValue)),
          0,
          0
        )} ${selectedArmorToken} for ${formatDecimals(
          receiveAmount(),
          0,
          0
        )} ${selectedEaseToken}`,
        status:
          currentStep == Steps.SWAP_TOKENS
            ? 'current'
            : currentStep < Steps.SWAP_TOKENS
            ? 'upcoming'
            : 'complete'
      }
    ],
    [currentStep, swapValue, selectedArmorToken, selectedEaseToken]
  )

  const stepsEase = useMemo(
    (): ModalStep[] => [
      {
        name: 'Approve Transfer',
        description: `Approve ${formatDecimals(
          Number(formatDelimiter(swapValue)),
          0,
          0
        )} ${selectedArmorToken} tokens to be swapped.`,
        status: currentStep == Steps.APPROVE ? 'current' : 'complete'
      },
      {
        name: 'Swap',
        description: `Swap ${formatDecimals(
          Number(formatDelimiter(swapValue)),
          0,
          0
        )} ${selectedArmorToken} for ${formatDecimals(
          receiveAmount(),
          0,
          0
        )} ${selectedEaseToken}`,
        status:
          currentStep == Steps.SWAP_TOKENS
            ? 'current'
            : currentStep < Steps.SWAP_TOKENS
            ? 'upcoming'
            : 'complete'
      }
    ],
    [currentStep, swapValue, selectedArmorToken, selectedEaseToken]
  )

  if (isLoading) {
    return <PageLoading title={'$EASE Token Swap'}>Loading...</PageLoading>
  }

  return (
    <div>
      {isOnWrongChain && (
        <div
          className={'mx-auto px-2 md:px-0 mt-4'}
          style={{ maxWidth: '520px' }}
        >
          <WrongChainAlert
            chainId={chainId ? chainId : 0}
            className={'mb-4 mt-8'}
          />
        </div>
      )}
      {showSuccessInfo ? (
        <div
          className={'mx-auto px-2 md:px-0 mt-4'}
          style={{ maxWidth: '420px' }}
        >
          <InfoAlert>
            <>
              {selectedEaseToken == '$gvEASE' ? (
                <>
                  Swap successful. You can now use your $gvEASE tokens
                  <Link
                    to="/gv-dashboard"
                    className="text-orange-500 underline"
                  >
                    {' '}
                    in our app{' '}
                  </Link>{' '}
                  to
                  <span>
                    {' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://ease.org/learn-crypto-defi/category/get-defi-cover-at-ease/product-guides/"
                      className="text-orange-500 underline"
                    >
                      stake, bribe and lease.
                    </a>
                  </span>
                </>
              ) : (
                <>
                  Swap successful. You can now deposit your $EASE tokens
                  <Link
                    to="/gv-dashboard"
                    className="text-orange-500 underline"
                  >
                    {' '}
                    in our app{' '}
                  </Link>{' '}
                  and you'll receive $gvEASE which you can use to
                  <span>
                    {' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://ease.org/learn-crypto-defi/category/get-defi-cover-at-ease/product-guides/"
                      className="text-orange-500 underline"
                    >
                      stake, bribe and lease.
                    </a>
                  </span>
                </>
              )}
            </>
          </InfoAlert>
        </div>
      ) : showPowerWarning ? (
        <div
          className={'mx-auto px-2 md:px-0 mt-4'}
          style={{ maxWidth: '420px' }}
        >
          <InformationAlert>
            <div>
              This address isn’t able to claim bonus $gvEASE, read more 
              <span>
                {''}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/product-guides/swapping-varmor-to-gvease/"
                  className="text-orange-500 underline"
                >
                  here
                </a>
                .
              </span>
            </div>
          </InformationAlert>
        </div>
      ) : (
        <div
          className={'mx-auto px-2 md:px-0 mt-4'}
          style={{ maxWidth: '420px' }}
        >
          <InfoAlert>
            <>
              The $EASE token swap is live
              <span>
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://ease.org/the-ease-token-swap-is-live/"
                  className="text-orange-500 underline"
                >
                  read more
                </a>
                .
              </span>
            </>
          </InfoAlert>
        </div>
      )}

      {!account && (
        <ConnectWalletPage
          title={<></>}
          text={'Please connect wallet to begin swapping your tokens'}
        />
      )}

      <div className={'mx-auto px-2 md:px-0'} style={{ maxWidth: '420px' }}>
        <PageTitle>
          <div className={'mt-4 flex justify-between'}>
            <div>
              <div className={'flex gap-x-2 justify-center items-center'}>
                {pageTitle}{' '}
              </div>
            </div>
          </div>
        </PageTitle>

        <div className={'pt-4 md:pt-4 mb-8 mx-auto'}>
          <div
            className={'bg-blackop-50 text-white rounded-xl py-4 px-1 text-lg'}
          >
            <div className={'px-3 pb-3 font-poppins text-base'}>
              <div>Using this form you can swap:</div>
              <div className={'text-sm text-white rounded-md p-1 pl-4 mt-1'}>
                <div>$Armor -{'>'} $EASE</div>
                <div>$Armor -{'>'} $gvEASE</div>
                <div>$vArmor -{'>'} $EASE</div>
                <div>$vArmor -{'>'} $gvEASE</div>
              </div>
            </div>
            <div
              className={
                'rounded-lg bg-blackop-30 mx-3 p-2 hover:bg-blackop-40'
              }
            >
              <div className={'flex justify-between text-xs items-center mb-1'}>
                <div className={'text-xs text-gray-300 mb-1'}>You'll Swap</div>
                <div>
                  Balance:{' '}
                  <span className={'font-bold font-sans'}>
                    {currentBalance() &&
                      formatDecimals(
                        Number(formatUnits(currentBalance(), 18)),
                        2,
                        2
                      )}
                  </span>{' '}
                  {selectedArmorToken === '$Armor' && (
                    <>
                      [{' '}
                      <a
                        onClick={() => setMax()}
                        href={'#'}
                        className={'text-orange-500 font-bold underline'}
                      >
                        MAX
                      </a>{' '}
                      ]
                    </>
                  )}
                </div>
              </div>
              <div className={'flex justify-between text-sm items-center'}>
                <div
                  className={'text-lg flex items-center gap-x-2 cursor-pointer'}
                  onClick={() => toggleSelectedArmorToken()}
                >
                  <img
                    src={'/armorlogo.png'}
                    className={'h-4 w-4'}
                    alt={'armor'}
                  />
                  <div>{selectedArmorToken}</div>
                  <div>
                    <UpDownArrowsIcon className={'h-6 w-6'} />
                  </div>
                </div>
                <div className={'flex-shrink'}>
                  <input
                    type={'text'}
                    className={classNames(
                      'font-medium text-white text-right border border-transparent focus:ring-orange-500 focus:border-orange-500 sm:text-lg rounded-md bg-blackop-20 py-1 px-2',
                      `${selectedArmorToken == '$vArmor' && 'opacity-75'}`
                    )}
                    value={swapValue}
                    pattern="[0-9\.]*"
                    onChange={(e) => onSwapChange(e)}
                    disabled={selectedArmorToken == '$vArmor'}
                  />
                </div>
              </div>
            </div>
            <div className={'flex justify-center'}>
              <ArrowCircleDownIcon
                className={'h-6 w-6 text-blackop-50'}
              ></ArrowCircleDownIcon>
            </div>
            <div
              className={
                'rounded-lg bg-blackop-30 mx-3 p-2 hover:bg-blackop-40'
              }
            >
              <div className={'flex justify-between items-center'}>
                <div
                  className={clsx(
                    'text-lg flex items-center gap-x-2 cursor-pointer'
                  )}
                  onClick={() => toggleSelectedEaseToken()}
                >
                  <img
                    src={'/logo192.png'}
                    className={'h-4 w-4'}
                    alt={'armor'}
                  />
                  <div>{selectedEaseToken}</div>
                  <div>
                    <UpDownArrowsIcon className={'h-6 w-6'} />
                  </div>
                </div>
                <div>
                  <div className={'flex justify-end'}>
                    <div className={'text-xs text-gray-300 mb-1'}>
                      You'll receive
                    </div>
                  </div>
                  <div className={'flex justify-end text-sm items-end'}>
                    <div
                      className={
                        'text-2xl flex gap-x-1 items-center justify-end'
                      }
                    >
                      ≈ {formatDecimals(receiveAmount(), 0, 0)}{' '}
                      <img
                        src={'./logo192.png'}
                        className={'h-4 w-4'}
                        alt={'EASE Token'}
                      />
                      <span className={'text-base text-gray-300'}>
                        {selectedEaseToken}
                      </span>
                    </div>
                  </div>
                  {selectedArmorToken == '$vArmor' &&
                    selectedEaseToken == '$gvEASE' && (
                      <div
                        className={'text-xs text-gray-500 mb-1 text-right mt-1'}
                      >
                        Includes ≈{' '}
                        <span className={'font-medium'}>
                          {formatDecimals(bonusAmount(), 0, 0)}
                        </span>{' '}
                        bonus gvEASE
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className={'px-3 mt-4'}>
              <Button
                onClick={() =>
                  selectedEaseToken == '$EASE' ? onSwapEase() : onSwapGvEase()
                }
                variant={'pink'}
                size={'base'}
                classes={'w-full block'}
                disabled={showPowerWarning || !account}
              >
                Swap Tokens
              </Button>
            </div>
          </div>
        </div>
      </div>

      <LoadingModal
        open={loadingModalOpen}
        title={loadingModalTitle}
        onClose={() => {}}
      >
        <div className={'pt-8 mb-4 border-t border-gray-100'}>
          <LoadingModalSteps
            steps={selectedEaseToken == '$gvEASE' ? stepsGvEase : stepsEase}
          />
        </div>
      </LoadingModal>
    </div>
  )
}
