import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { Func } from 'mocha'
import {
  classNames,
  countDecimals,
  formatDecimals,
  weiToEth,
  formatDelimiter
} from '../classes/helpers'
import { Button } from './Button'
import { useImmer } from 'use-immer'
import clsx from 'clsx'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { BigNumber } from 'ethers'

export interface AmountModalData {
  isOpen: boolean
  title: string
  buttonText: string
  symbol: string
  maxAmount: string
  onConfirm: Function
}

export const AmountModal: React.FC<{
  children: React.PropsWithChildren<any>
  title: ReactNode | string
  confirmText: string
  maxAmount: string
  symbol: string
  onChange: Function
  onConfirm: Function
  onClose: Function
  open: boolean
}> = ({
  children,
  title = 'Confirm',
  confirmText = 'OK',
  symbol,
  onConfirm,
  onChange,
  onClose,
  maxAmount,
  open = false
}) => {
  const cancelButtonRef = useRef(null)
  const getMax = () => {
    if (Number(maxAmount) < 1) {
      return weiToEth(maxAmount).toFixed(10)
    }

    return weiToEth(maxAmount).toFixed(2)
  }
  const [value, setValue] = useState(
    maxAmount != '' ? formatDecimals(parseFloat(getMax()), 3, 0) : '0'
  )

  useEffect(() => {
    if (open) {
      if (maxAmount != '') setValue(formatDecimals(parseFloat(getMax()), 3, 0))
    }
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 font-poppins"
                  >
                    {title}
                  </Dialog.Title>

                  <div className={'flex justify-center mt-4'}>
                    <div className={'flex gap-x-2 items-center'}>
                      <input
                        type="text"
                        name="price"
                        id="price"
                        disabled={false}
                        value={value}
                        pattern="[0-9]+[.]{,1}[0-9]*"
                        autoComplete={'off'}
                        onChange={(e) => {
                          let val = ''
                          if (countDecimals(formatDelimiter(value)) > 10) {
                            if (Number(val) < 1) {
                              val = parseFloat(
                                formatDelimiter(e.target.value)
                              ).toFixed(10)
                            } else {
                              val = parseFloat(
                                formatDelimiter(e.target.value)
                              ).toFixed(4)
                            }

                            if (
                              parseUnits(val, 18).gt(BigNumber.from(maxAmount))
                            ) {
                              val = formatUnits(maxAmount, 18)
                            }

                            setValue(formatDecimals(parseFloat(val), 3, 0))
                            onChange(val)
                            return
                          }
                          val = e.target.validity.valid
                            ? formatDelimiter(e.target.value)
                            : formatDelimiter(value)

                          if (
                            parseUnits(val, 18).gt(BigNumber.from(maxAmount))
                          ) {
                            val = formatUnits(maxAmount, 18)
                          }
                          setValue(formatDecimals(parseFloat(val), 3, 0))
                          onChange(val)
                        }}
                        className={classNames(
                          value == '0' ? 'bg-gray-200 text-gray-500' : '',
                          'font-medium text-black text-right border border-orange-500 focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'
                        )}
                        placeholder="0.00"
                        aria-describedby="price-currency"
                      />
                      <div>{symbol}</div>
                    </div>
                  </div>

                  <div className={'text-sm pt-4'}>
                    <span className={'font-bold'}>Current Balance:</span>{' '}
                    <span
                      className={'text-pink-500 cursor-pointer'}
                      onClick={() =>
                        setValue(formatDecimals(parseFloat(getMax()), 3, 0))
                      }
                    >
                      {maxAmount != '' &&
                        formatDecimals(weiToEth(maxAmount), 3, 0)}
                    </span>
                  </div>
                </div>
              </div>
              {children}
              <div
                className={clsx(
                  'sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense',
                  !children ? 'mt-5 sm:mt-6' : ''
                )}
              >
                <Button
                  onClick={() => onConfirm()}
                  disabled={Number(maxAmount) == 0}
                  classes={'sm:col-start-2 capitalize font-normal'}
                >
                  {confirmText}
                </Button>
                <Button
                  onClick={() => onClose()}
                  buttonRef={cancelButtonRef}
                  variant={'white'}
                  classes={'capitalize font-normal'}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
