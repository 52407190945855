import { Step } from 'react-joyride'
import React, { useMemo, useState } from 'react'
import { infoTabSteps } from './infoTabSteps'
import { opportunitiesSteps } from './opportunitiesSteps'
import { activeDepositSteps } from './activeDepositSteps'
import { depositTabSteps } from './depositTabSteps'
import { farmingTabSteps } from './farmingTabSteps'
import { tabPanelsSteps } from './tabPanelsSteps'

export const useJoyrideSteps = (account: string | null | undefined) => {
  const [overrideSteps, setOverrideSteps] = useState<Array<Step> | null>([])

  const steps = useMemo(() => {
    if (overrideSteps && overrideSteps.length > 0) {
      return overrideSteps
    }

    // initialize the first step (start the tour)
    let _steps: Array<Step> = [
      {
        target: '.ease-app',
        content: (
          <div className={'text-center p-4'}>
            <div>
              You can read{' '}
              <a
                href={
                  'https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/product-guides/use-ease-uninsurance-app/'
                }
                className={'text-pink-500 underline font-bold'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                instructions here.
              </a>
            </div>
            <div className={'mt-2'}>Would you like to get a guided tour?</div>
            <div className={'text-gray-600 mt-2 text-sm'}>
              You can stop the tour at any moment by clicking outside the tour
              overlay area.
            </div>
          </div>
        ),
        placement: 'center',
        disableBeacon: true,
        title: (
          <div className={'text-center text-pink-500 text-lg font-bold'}>
            This is the Ease Uninsurance Dashboard.
          </div>
        )
      }
    ]

    // only show this if the user is not connected to wallet
    if (!account) {
      _steps.push({
        target: '.connect-wallet',
        content: (
          <>
            <div>
              If you want to use the app to cover your assets, you will need to
              connect your wallet.
            </div>
            <div className={'mt-4'}>
              You can use any [Connect] button to do so.
            </div>
          </>
        ),
        disableBeacon: true
      })
    }

    // add remaining steps
    _steps.push(...activeDepositSteps)
    _steps.push(...opportunitiesSteps)
    _steps.push(...tabPanelsSteps)
    _steps.push(...depositTabSteps(account))

    _steps.push(...farmingTabSteps)
    _steps.push(...infoTabSteps)

    return _steps
  }, [account, overrideSteps])

  return { steps, setOverrideSteps }
}
